import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { MdAdd } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { IoRemove } from "react-icons/io5";
import axiosInstance from './axios_instance';
import { MdOutlineFileDownload } from "react-icons/md";
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mantine/core';
import { counter } from '@fortawesome/fontawesome-svg-core';
// import { formatData } from 'export-to-csv/output/lib/helpers';


const FinancePage = () => {
    const [previewImages, setPreviewImages] = useState([]);
    const [employeeInfo, setEmployeeInfo] = useState({})
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation();
    const receivedData = location.state
    const [numAdditionalFields, setNumAdditionalFields] = useState(0);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [otherDocuments, setotherDocuments] = useState(null);
    const [otherDocumentsSizeError, setotherDocumentsSizeError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [employeeDataList, setemployeeDataList] = useState([
        {
            hikeDate: '',
            hikeAmount: '',
            hikePercent: ''
        }
    ]);

    const [employeeBonusList, setemployeeBonusList] = useState([
        {
            bonusDate: '',
            bonusAmount: '',
            bonusType: ''
        }
    ]);

    const [documents, setDocument] = useState(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   
    
    const [docTypeError, setDocTypeError] = useState('');

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };
    const handleFileChange = (event) => {
        // Capture the selected file
        const file = event.target.files[0];
        setUploadedFile(file);
    };
    const [employeeDataById, setemployeeDataById] = useState({
        // employeeId: receivedData?.data.employeeId,
        // employeeName: receivedData?.data.employeeName,
        // emailId: receivedData?.data.emailId,
        _id: '',
        employeeName: '',
        emailId: '',
        country: 'India'
    });
    console.log("Hey Received Data is Here",receivedData)
    const [employeeData, setemployeeData] = useState({
        // employeeId: receivedData?.data.employeeId,
        // employeeName: receivedData?.data.employeeName,
        // emailId: receivedData?.data.emailId,
        _id: '',
        employeeName: '',
        emailId: '',
        basicPayAnnual: '',
        basicPayMonthly: '',
        hraAnnual: '' ,
        hraMonthly: '',
        specialAllowanceAnnual: '',
        specialAllowanceMonthly: '',
        // housingAllowanceAnnual: '',
        // housingAllowanceMonthly:'',
        grossTotalAnnual: '',
        grossTotalMonthly: '',
        transportAllowanceAnnual: '',
        transportAllowanceMonthly: '',
        otherAllowanceAnnual: '',
        otherAllowanceMonthly: '',
        reimbursementExpenseAnnual: '',
        reimbursementExpenseMonthly: '',
        variablePayAnnual: '',
        variablePayMonthly: '',
        epfAnnual: '',
        epfMonthly: '',
        ctcAnnual: '',
        ctcMonthly: '',
        currency: '',
        currency1: '',
        currency2: '',
        currency3: '',
        currency4: '',
        currency5:'',
        currency6:'',
        customCurrency: '',
        customCurrency1: '',
        customCurrency2: '',
        customCurrency3: '',
        customCurrency4: '',
        customCurrency5: '',
        customCurrency6: '',
        updatedDate:'',
        hikeDataList: employeeDataList,
        bonusDataList: employeeBonusList,

    })

    const role = localStorage.getItem('Role');
    const isAdmin = role === 'ADMIN';
    const isSuperAdmin = role === 'SUPER_ADMIN';
    const isAdminFinanace = role === 'ADMIN_FINANCE';
    const isAssociateFinance = role === "ASSOCIATE_FINANCE";
    const isAssociateHR = role === "ASSOCIATE_HR";

    const [userName, setUserName] = useState();

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const documentFileTypes = [
        'image/png',
         'image/jpg', 
         'image/jpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    const handleLogout = () => {
        // Clear session data
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("islogged", false);
        window.location.href = "/";

        // Redirect to the login page (replace '/login' with your actual login route)
        // navigate('/login');

    }

    const formatDateForDisplay = (isoDateString) => {
        if (!isoDateString) return null; // Return null if input is invalid or null
        const dateObject = new Date(isoDateString);
        if (isNaN(dateObject.getTime())) return null; // Return null if date is invalid
        return dateObject;
    };

 useEffect(() => {
    const user = localStorage.getItem('Username');
    setUserName(user);

    const fetchData = async () => {
        if (receivedData?.data) {
            try {
                const storedEmployee = receivedData.data._id;
                const employeeName = receivedData.data.employeeName;
                const emailId = receivedData.data.emailId;
                console.log("storedEmployee",storedEmployee,employeeName)
                const token = localStorage.getItem('Token');

                if (!token) {
                    navigate("/login");
                    return;
                }

                if (!storedEmployee) {
                    navigate("/dashboard");
                    return;
                }

                const response = await axiosInstance.get('api/getEmployeeFinanceDataById/' + storedEmployee);
                console.log("response",response)
                const storedCountry = receivedData.data.country;
                if (response.data && Object.keys(response.data).length > 0) {
                    // console.log(storedCountry+"storedCountry");
                    // const splitValue = (value) => {
                    //     if (!value) return { amount: '', currency: '' };
                    //     const [amount, ...currencyArray] = value.split(' ');
                    //     const currency = currencyArray.join(' ');
                    //     return { amount, currency };
                    // };
                    const splitValue = (value) => {
                        if (!value) return { amount: 0, currency: "others" }; // Default values if input is invalid or null
                    
                        // Split the value by space
                        const parts = value.split(' ');
                    
                        // If there's only one part, consider it as the amount
                        if (parts.length === 1) {
                            const amount = parseFloat(parts[0].replace(/[^0-9.]/g, '')); // Extract numeric characters
                            return { amount, currency: "others" }; // Default currency if not specified
                        }
                    
                        // If there are two parts, consider the first as amount and second as currency
                        if (parts.length === 2) {
                            const amount = parseFloat(parts[0].replace(/[^0-9.]/g, '')); // Extract numeric characters
                            const currency = parts[1].toLowerCase(); // Convert currency to lowercase
                            return { amount, currency };
                        }
                    
                        // If more than two parts, consider it as invalid and return default values
                        return { amount: 0, currency: "others" };
                    };
                    
                    const processedData = {
                        ...response.data,
                        updatedDate: formatDateForDisplay(receivedData.data.updatedDate)
                    };

                    console.log("processedData",processedData)
                    
                    switch (storedCountry) {
                        case 'India':
                          
                            Object.assign(processedData, {
                                basicPayAnnual: splitValue(response.data.basicPayAnnual).amount,
                            basicPayMonthly: splitValue(response.data.basicPayMonthly).amount,
                            currency: (splitValue(response.data.basicPayAnnual).currency === "dirham" || splitValue(response.data.basicPayAnnual).currency === "rupees" || splitValue(response.data.basicPayAnnual).currency === "dollar") ? splitValue(response.data.basicPayAnnual).currency : "others",
                            customCurrency: splitValue(response.data.basicPayAnnual).currency,
                    
                            hraAnnual: splitValue(response.data.hraAnnual).amount,
                            hraMonthly: splitValue(response.data.hraMonthly).amount,
                            currency1: (splitValue(response.data.hraAnnual).currency === "dirham" || splitValue(response.data.hraAnnual).currency === "rupees" || splitValue(response.data.hraAnnual).currency === "dollar") ? splitValue(response.data.hraAnnual).currency : "others",
                            customCurrency1: splitValue(response.data.hraAnnual).currency,
                    
                            specialAllowanceAnnual: splitValue(response.data.specialAllowanceAnnual).amount,
                            specialAllowanceMonthly: splitValue(response.data.specialAllowanceMonthly).amount,
                            currency2: (splitValue(response.data.specialAllowanceAnnual).currency === "dirham" || splitValue(response.data.specialAllowanceAnnual).currency === "rupees" || splitValue(response.data.specialAllowanceAnnual).currency === "dollar") ? splitValue(response.data.specialAllowanceAnnual).currency : "others",
                            customCurrency2: splitValue(response.data.specialAllowanceAnnual).currency,
                    
                            grossTotalAnnual: splitValue(response.data.grossTotalAnnual).amount,
                            grossTotalMonthly: splitValue(response.data.grossTotalMonthly).amount,
                            currency3: (splitValue(response.data.grossTotalAnnual).currency === "dirham" || splitValue(response.data.grossTotalAnnual).currency === "rupees" || splitValue(response.data.grossTotalAnnual).currency === "dollar") ? splitValue(response.data.grossTotalAnnual).currency : "others",
                            customCurrency3: splitValue(response.data.grossTotalAnnual).currency,

                            epfAnnual: splitValue(response.data.epfAnnual).amount,
                            epfMonthly: splitValue(response.data.epfMonthly).amount,
                            currency4: (splitValue(response.data.epfAnnual).currency === "dirham" || splitValue(response.data.epfAnnual).currency === "rupees" || splitValue(response.data.epfAnnual).currency === "dollar") ? splitValue(response.data.epfAnnual).currency : "others",
                            customCurrency4: splitValue(response.data.epfAnnual).currency,

                            ctcAnnual: splitValue(response.data.ctcAnnual).amount,
                            ctcMonthly: splitValue(response.data.ctcMonthly).amount,
                            currency5: (splitValue(response.data.ctcAnnual).currency === "dirham" || splitValue(response.data.ctcAnnual).currency === "rupees" || splitValue(response.data.ctcAnnual).currency === "dollar") ? splitValue(response.data.ctcAnnual).currency : "others",
                            customCurrency5: splitValue(response.data.ctcAnnual).currency,

                            });
                            break;
                        case 'Dubai':
                            
                            Object.assign(processedData, {
                                basicPayAnnual: splitValue(response.data.basicPayAnnual).amount,
                                basicPayMonthly: splitValue(response.data.basicPayMonthly).amount,
                                currency: (splitValue(response.data.basicPayAnnual).currency === "dirham" || splitValue(response.data.basicPayAnnual).currency === "rupees" || splitValue(response.data.basicPayAnnual).currency === "dollar") ? splitValue(response.data.basicPayAnnual).currency : "others",
                                customCurrency: splitValue(response.data.basicPayAnnual).currency,
                        
                                hraAnnual: splitValue(response.data.hraAnnual).amount,
                                hraMonthly: splitValue(response.data.hraMonthly).amount,
                                currency1: (splitValue(response.data.hraAnnual).currency === "dirham" || splitValue(response.data.hraAnnual).currency === "rupees" || splitValue(response.data.hraAnnual).currency === "dollar") ? splitValue(response.data.hraAnnual).currency : "others",
                                customCurrency1: splitValue(response.data.hraAnnual).currency,

                                otherAllowanceAnnual: splitValue(response.data.otherAllowanceAnnual).amount,
                                otherAllowanceMonthly: splitValue(response.data.otherAllowanceMonthly).amount,
                                currency4: (splitValue(response.data.otherAllowanceAnnual).currency === "dirham" || splitValue(response.data.otherAllowanceAnnual).currency === "rupees" || splitValue(response.data.otherAllowanceAnnual).currency === "dollar") ? splitValue(response.data.hraAnnual).currency : "others",
                                customCurrency4: splitValue(response.data.otherAllowanceAnnual).currency,
                        
                                transportAllowanceAnnual: splitValue(response.data.transportAllowanceAnnual).amount,
                                transportAllowanceMonthly: splitValue(response.data.transportAllowanceMonthly).amount,
                                currency3: (splitValue(response.data.transportAllowanceAnnual).currency === "dirham" || splitValue(response.data.transportAllowanceAnnual).currency === "rupees" || splitValue(response.data.transportAllowanceAnnual).currency === "dollar") ? splitValue(response.data.transportAllowanceAnnual).currency : "others",
                                customCurrency3: splitValue(response.data.transportAllowanceAnnual).currency,
                        
                                reimbursementExpenseAnnual: splitValue(response.data.reimbursementExpenseAnnual).amount,
                                reimbursementExpenseMonthly: splitValue(response.data.reimbursementExpenseMonthly).amount,
                                currency6: (splitValue(response.data.reimbursementExpenseAnnual).currency === "dirham" || splitValue(response.data.reimbursementExpenseAnnual).currency === "rupees" || splitValue(response.data.reimbursementExpenseAnnual).currency === "dollar") ? splitValue(response.data.reimbursementExpenseAnnual).currency : "others",
                                customCurrency6: splitValue(response.data.reimbursementExpenseAnnual).currency,
                        
                                variablePayAnnual: splitValue(response.data.variablePayAnnual).amount,
                                variablePayMonthly: splitValue(response.data.variablePayMonthly).amount,
                                currency2: (splitValue(response.data.variablePayAnnual).currency === "dirham" || splitValue(response.data.variablePayAnnual).currency === "rupees" || splitValue(response.data.variablePayAnnual).currency === "dollar") ? splitValue(response.data.variablePayAnnual).currency : "others",
                                customCurrency2: splitValue(response.data.variablePayAnnual).currency,
                        
                                ctcAnnual: splitValue(response.data.ctcAnnual).amount,
                                ctcMonthly: splitValue(response.data.ctcMonthly).amount,
                                currency5: (splitValue(response.data.ctcAnnual).currency === "dirham" || splitValue(response.data.ctcAnnual).currency === "rupees" || splitValue(response.data.ctcAnnual).currency === "dollar") ? splitValue(response.data.ctcAnnual).currency : "others",
                                customCurrency5: splitValue(response.data.ctcAnnual).currency,

                            });
                            break;
                            //other then india and usa country's
                        default:
                            
                            Object.assign(processedData, {
                                basicPayAnnual: splitValue(response.data.basicPayAnnual).amount,
                                basicPayMonthly: splitValue(response.data.basicPayMonthly).amount,
                                currency: (splitValue(response.data.basicPayAnnual).currency === "dirham" || splitValue(response.data.basicPayAnnual).currency === "rupees" || splitValue(response.data.basicPayAnnual).currency === "dollar") ? splitValue(response.data.basicPayAnnual).currency : "others",
                                customCurrency: splitValue(response.data.basicPayAnnual).currency,
                            
                                hraAnnual: splitValue(response.data.hraAnnual).amount,
                                hraMonthly: splitValue(response.data.hraMonthly).amount,
                                currency1: (splitValue(response.data.hraAnnual).currency === "dirham" || splitValue(response.data.hraAnnual).currency === "rupees" || splitValue(response.data.hraAnnual).currency === "dollar") ? splitValue(response.data.hraAnnual).currency : "others",
                                customCurrency1: splitValue(response.data.hraAnnual).currency,
                            
                                specialAllowanceAnnual: splitValue(response.data.specialAllowanceAnnual).amount,
                                specialAllowanceMonthly: splitValue(response.data.specialAllowanceMonthly).amount,
                                currency2: (splitValue(response.data.specialAllowanceAnnual).currency === "dirham" || splitValue(response.data.specialAllowanceAnnual).currency === "rupees" || splitValue(response.data.specialAllowanceAnnual).currency === "dollar") ? splitValue(response.data.specialAllowanceAnnual).currency : "others",
                                customCurrency2: splitValue(response.data.specialAllowanceAnnual).currency,
                            

                            
                                ctcAnnual: splitValue(response.data.ctcAnnual).amount,
                                ctcMonthly: splitValue(response.data.ctcMonthly).amount,
                                currency5: (splitValue(response.data.ctcAnnual).currency === "dirham" || splitValue(response.data.ctcAnnual).currency === "rupees" || splitValue(response.data.ctcAnnual).currency === "dollar") ? splitValue(response.data.ctcAnnual).currency : "others",
                                customCurrency5: splitValue(response.data.ctcAnnual).currency,
                    
                                
                            });
                            break;
                    }
                    
                    setemployeeData(processedData);
                    // setemployeeData(employeeData.updatedDate);
                    setemployeeDataList(processedData.hikeDataList || []);
                    setemployeeBonusList(processedData.bonusDataList || []);
                    setDocument(processedData.totalDocuments[0]?.hikeLetter || []);
                    setemployeeDataById(processedData);
                    setemployeeDataById(receivedData.data);
                
                } else {
                    // If response is null, store only employee ID and name
                     setemployeeData({
                        _id: storedEmployee,
                        employeeName: employeeName,
                        emailId:emailId,
                    });
                }
            } catch (error) {
                handleFetchError(error);
            }
        } else if (employeeData._id) {
            const response = await axiosInstance.get('api/getEmployeeById/' + employeeData._id);
            console.log("this is your response", response.data)
            if (response.data && Object.keys(response.data).length > 0) {
                setemployeeData(response.data);
                setemployeeDataById(response.data);
            } else {
                resetEmployeeData();
            }

       
        }
    };

    fetchData();
   
}, [receivedData?.data, employeeDataById.employeeId]);

    const resetEmployeeData = () => {
        setemployeeData({
            _id: '',
            employeeName: '',
            emailId: '',
            basicPayAnnual: '',
            basicPayMonthly: '',
            hraAnnual: '',
            hraMonthly: '',
            specialAllowanceAnnual: '',
            specialAllowanceMonthly: '',
            grossTotalAnnual: '',
            grossTotalMonthly: '',
            transportAllowanceAnnual: '',
            transportAllowanceMonthly: '',
            otherAllowanceAnnual: '',
            otherAllowanceMonthly: '',
            reimbursementExpenseAnnual: '',
            reimbursementExpenseMonthly: '',
            variablePayAnnual: '',
            variablePayMonthly: '',
            epfAnnual: '',
            epfMonthly: '',
            ctcAnnual: '',
            ctcMonthly: '',
            currency: '',
            currency1: '',
            currency2: '',
            currency3: '',
            currency4: '',
            currency5: '',
            hikeDataList: [],
            bonusDataList: [],
        });
        setemployeeDataList([{
            hikeDate: '',
            hikeAmount: '',
            hikePercent: ''
        }]);
        setemployeeBonusList([{
            bonusDate: '',
            bonusAmount: '',
            bonusType: ''
        }]);
    };
    const handleFetchError = (error) => {
        console.error("error", error);
        // toast.error("Token Expired .......Please Login again....!", { position: toast.POSITION.TOP_CENTER });
        // sessionStorage.clear();
        // localStorage.clear();
        // localStorage.setItem("islogged", false);
        // navigate("/login");
    };
    const navigate = useNavigate();
    const fileInputRefs = {
        hikeLetter: useRef(null),
        otherDocuments: useRef(null),
    };

    const handleBack = () => {
        navigate("/finance")
    }
    const formatDateForBackend = (dateString) => {
        if (!dateString || isNaN(Date.parse(dateString))) {
            return 'Invalid Date'; // Handle invalid date
        }
    
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Return date in YYYY-MM-DD format
    };
    
    
    

    const handleSubmit = async (e) => {
        // alert("clciked");
        const finalCurrency = employeeData.currency === "others" ? employeeData.customCurrency : employeeData.currency;
        const finalCurrency1 = employeeData.currency1 === "others" ? employeeData.customCurrency1 : employeeData.currency1;
        const finalCurrency2 = employeeData.currency2 === "others" ? employeeData.customCurrency2 : employeeData.currency2;
        const finalCurrency3 = employeeData.currency3 === "others" ? employeeData.customCurrency3 : employeeData.currency3;
        const finalCurrency4 = employeeData.currency4 === "others" ? employeeData.customCurrency4 : employeeData.currency4;
        const finalCurrency5 = employeeData.currency5 === "others" ? employeeData.customCurrency5 : employeeData.currency5;
        const finalCurrency6 = employeeData.currency6 === "others" ? employeeData.customCurrency6 : employeeData.currency6;

        const { currency,currency1,currency2,currency3,currency4,currency5,grossTotalAnnual,grossTotalMonthly, basicPayMonthly, basicPayAnnual, hraMonthly, hraAnnual,specialAllowanceAnnual,specialAllowanceMonthly, transportAllowanceMonthly, transportAllowanceAnnual, otherAllowanceMonthly, otherAllowanceAnnual, reimbursementExpenseMonthly, reimbursementExpenseAnnual, variablePayMonthly, variablePayAnnual, epfMonthly, epfAnnual, ctcMonthly, ctcAnnual } = employeeData;

        // Append currency to specific fields for us
        const updatedBasicPayMonthly = basicPayMonthly ? `${basicPayMonthly} ${finalCurrency}` : '';
        const updatedBasicPayAnnual = basicPayAnnual ? `${basicPayAnnual} ${finalCurrency}` : '';
        // const updatedBasicPayMonthly = basicPayMonthly ? `${basicPayMonthly} ${currency}` : '';
        // const updatedBasicPayAnnual = basicPayAnnual ? `${basicPayAnnual} ${currency}` : '';
        const updatedHraMonthly = hraMonthly ? `${hraMonthly} ${finalCurrency1}` : '';
        const updatedHraAnnual = hraAnnual ? `${hraAnnual} ${finalCurrency1}` : '';
        const updatespecialAllowanceMonthly = specialAllowanceMonthly ? `${specialAllowanceMonthly} ${finalCurrency2}`:'';
        const updatespecialAllowanceAnnual = specialAllowanceAnnual ? `${specialAllowanceAnnual} ${finalCurrency2}`:'';
// dubai
        // const updatedhousingAllowanceAnnual = housingAllowanceAnnual ? `${housingAllowanceAnnual} ${currency}` : '';
        const updatedTransportAllowanceMonthly = transportAllowanceMonthly ? `${transportAllowanceMonthly} ${finalCurrency3}` : '';
        const updatedTransportAllowanceAnnual = transportAllowanceAnnual ? `${transportAllowanceAnnual} ${finalCurrency3}` : '';
        const updatedOtherAllowanceMonthly = otherAllowanceMonthly ? `${otherAllowanceMonthly} ${finalCurrency4}` : '';
        const updatedOtherAllowanceAnnual = otherAllowanceAnnual ? `${otherAllowanceAnnual} ${finalCurrency4}` : '';
        const updatedReimbursementExpenseMonthly = reimbursementExpenseMonthly ? `${reimbursementExpenseMonthly} ${finalCurrency6}` : '';
        const updatedReimbursementExpenseAnnual = reimbursementExpenseAnnual ? `${reimbursementExpenseAnnual} ${finalCurrency6}` : '';
        const updatedVariablePayMonthly = variablePayMonthly ? `${variablePayMonthly} ${finalCurrency2}` : '';
        const updatedVariablePayAnnual = variablePayAnnual ? `${variablePayAnnual} ${finalCurrency2}` : '';
        const updatedGrossTotalAnnual = grossTotalAnnual ? `${grossTotalAnnual} ${finalCurrency3}` : '';
        const updatedGrossTotalMonthly = grossTotalMonthly ? `${grossTotalMonthly} ${finalCurrency3}` : '';

        const updatedEpfMonthly = epfMonthly ? `${epfMonthly} ${finalCurrency4}` : '';
        const updatedEpfAnnual = epfAnnual ? `${epfAnnual} ${finalCurrency4}` : '';
        const updatedCtcMonthly = ctcMonthly ? `${ctcMonthly} ${finalCurrency5}` : '';
        const updatedCtcAnnual = ctcAnnual ? `${ctcAnnual} ${finalCurrency5}` : '';


        if (employeeDataById && employeeDataById.country == "US") {
            if (!employeeData?.basicPayAnnual || employeeData?.basicPayAnnual < 0) {
                setError('Basic Pay (Annual) is required and must be greater than 0')
                return;
            }

            if (!employeeData?.basicPayMonthly || employeeData?.basicPayMonthly < 0) {
                setError('Basic Pay (Monthly) is required and must be greater than 0')
                return;
            }
            // Validate hraAnnual
            if (!employeeData?.hraAnnual || employeeData?.hraAnnual < 0) {
                setError('HRA (Annual) is required and must be greater than or equal to 0')
                return;
            }

            // Validate hraMonthly
            if (!employeeData?.hraMonthly || employeeData?.hraMonthly < 0) {
                setError('HRA (Monthly) is required and must be greater than or equal to 0')
                return;
            }

            // Validate specialAllowanceAnnual
            if (!employeeData?.specialAllowanceAnnual || employeeData?.specialAllowanceAnnual < 0) {
                setError('Special Allowance (Annual) is required and must be greater than or equal to 0')
                return;

            }
            else {

                // setError("")
                // setemployeeData(null)
                try {

                    console.log("You Are Sending this data", employeeData)


                    const formData = new FormData();
                    // const updatedDate1 = formatDateForBackend(employeeData.updatedDate);

                    // formData.append('updatedDate', updatedDate1);
                    // formData.append('currency', currency);
                    formData.append('basicPayMonthly', updatedBasicPayMonthly);
                    formData.append('basicPayAnnual', updatedBasicPayAnnual);
                    formData.append('hraMonthly', updatedHraMonthly);
                    formData.append('hraAnnual', updatedHraAnnual);
                    formData.append('specialAllowanceAnnual',updatespecialAllowanceAnnual);
                    formData.append('specialAllowanceMonthly',updatespecialAllowanceMonthly);
                    formData.append('ctcAnnual',updatedCtcAnnual);
                    formData.append('ctcMonthly',updatedCtcMonthly);

                    console.log(formData+"form data");
                    // Append data to formData
                    // Object.entries(employeeData).forEach(([key, value]) => {
                    //     if (key === 'hikeDataList' || key === "bonusDataList") {
                    //         formData.append(key, JSON.stringify(value));
                    //     } else {
                    //         formData.append(key, value);
                    //     }
                    // });
                    Object.entries(employeeData).forEach(([key, value]) => {
                        if (key !== 'basicPayAnnual' && key !== 'basicPayMonthly' &&
                            key !== 'hraAnnual' && key !== 'hraMonthly' &&
                            key !== 'specialAllowanceAnnual' && key !== 'specialAllowanceMonthly' &&
                            key !== 'ctcAnnual' && key !== 'ctcMonthly') {
                    
                            if (key === 'updatedDate') {
                                // Check if value is a valid date before converting
                                const dateValue = new Date(value);
                                if (!isNaN(dateValue)) {
                                    formData.append(key, dateValue.toISOString());
                                } else {
                                    console.error('Invalid date value:', value);
                                    formData.append(key, null); // Handle invalid date
                                }
                            } else if (key === 'hikeDataList' || key === 'bonusDataList') {
                                formData.append(key, JSON.stringify(value));
                            } else {
                                formData.append(key, value);
                            }
                        }
                    });
                    
                    const response = await axios.post(
                        process.env.REACT_APP_IP + "api/financeData",
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `Bearer ${localStorage.getItem('Token')}`,
                            },
                        }
                    );

                    console.log(response);
                    alert("Done")
                    if (response.data === "EmployeeId do not exist") {
                        alert("EmployeeId does not exist.");
                        window.location.reload();
                        setemployeeData({});
                    } else if (response.data === "Error occurred ...") {
                        alert("Error occurred ...");

                        window.location.reload();
                        setemployeeData({});
                    } else {
                        if (isAssociateFinance) {
                            toast.success("Form Submission successful", { position: toast.POSITION.TOP_CENTER });
                            window.location.reload();
                        } else {
                            navigate('/finance');
                        }

                    }


                    // Clear employeeData after successful submission
                    setemployeeData({});
                } catch (error) {
                    // Handle errors
                    console.error('There was a problem with the fetch operation:', error);
                }

            }
        }

        else if (employeeDataById && employeeDataById.country == "Dubai") {

            if (!employeeData?.basicPayAnnual || employeeData?.basicPayAnnual < 0) {
                setError('Basic Pay (Annual) is required and must be greater than 0')
                return;
            }

            if (!employeeData?.basicPayMonthly || employeeData?.basicPayMonthly < 0) {
                setError('Basic Pay (Monthly) is required and must be greater than 0')
                return;
            }
            // Validate hraAnnual
            if (!employeeData?.hraAnnual || employeeData?.hraAnnual < 0) {
                setError('HRA (Annual) is required and must be greater than or equal to 0')
                return;
            }

            // Validate hraMonthly
            if (!employeeData?.hraMonthly || employeeData?.hraMonthly < 0) {
                setError('HRA (Monthly) is required and must be greater than or equal to 0')
                return;
            }

            // Validate specialAllowanceAnnual
            if (!employeeData?.transportAllowanceAnnual || employeeData?.transportAllowanceAnnual < 0) {
                setError('Transport Allowance (Annual) is required and must be greater than or equal to 0')
                return;

            }

            // Validate specialAllowanceMonthly
            if (!employeeData?.transportAllowanceMonthly || employeeData?.transportAllowanceMonthly < 0) {
                setError('Transport Allowance (Monthly) is required and must be greater than or equal to 0')
                return;
            }
            if (!employeeData?.otherAllowanceAnnual || employeeData?.otherAllowanceAnnual < 0) {
                setError('Other Allowance  (Annual) is required and must be greater than or equal to 0')
                return;
            }
            if (!employeeData?.otherAllowanceMonthly || employeeData?.otherAllowanceMonthly < 0) {
                setError('Other Allowance (Monthly) is required and must be greater than or equal to 0')
                return;
            }

            // Validate epfAnnual
            if (!employeeData?.reimbursementExpenseAnnual || employeeData?.reimbursementExpenseAnnual < 0) {
                setError('Reimbursement Expense (Annual) is required and must be greater than or equal to 0')
                return;

            }

            // Validate epfMonthly
            if (!employeeData?.reimbursementExpenseMonthly || employeeData?.reimbursementExpenseMonthly < 0) {
                setError('Reimbursement Expense (Monthly) is required and must be greater than or equal to 0')
                return;

            }
            if (!employeeData?.variablePayAnnual || employeeData?.variablePayAnnual < 0) {
                setError('Variable Pay (Annual) is required and must be greater than or equal to 0')
                return;

            }
            if (!employeeData?.variablePayMonthly || employeeData?.variablePayMonthly < 0) {
                setError('Variable Pay (Monthly) is required and must be greater than or equal to 0')
                return;

            }
            else {

                // setError("")
                // setemployeeData(null)
                try {

                    console.log(employeeData)


                    const formData = new FormData();
                    // const updatedDate1 = formatDateForBackend(employeeData.updatedDate);

                    // formData.append('updatedDate', updatedDate1);
                    formData.append('hraMonthly', updatedHraMonthly);
                    formData.append('hraAnnual', updatedHraAnnual); 
                    formData.append('basicPayMonthly', updatedBasicPayMonthly);
                    formData.append('basicPayAnnual', updatedBasicPayAnnual);
                    formData.append('transportAllowanceAnnual',updatedTransportAllowanceAnnual);
                    formData.append('transportAllowanceMonthly',updatedTransportAllowanceMonthly);
                    formData.append('otherAllowanceAnnual',updatedOtherAllowanceAnnual);
                    formData.append('otherAllowanceMonthly',updatedOtherAllowanceMonthly);
                    formData.append('reimbursementExpenseAnnual',updatedReimbursementExpenseAnnual);
                    formData.append('reimbursementExpenseMonthly',updatedReimbursementExpenseMonthly);
                    formData.append('variablePayAnnual',updatedVariablePayAnnual);
                    formData.append('variablePayMonthly',updatedVariablePayMonthly);
                    formData.append('ctcAnnual',updatedCtcAnnual);
                    formData.append('ctcMonthly',updatedCtcMonthly);

                    // formData.append('')

                    // Append data to formData
                    // Object.entries(employeeData).forEach(([key, value]) => {
                    //     if (key === 'hikeDataList' || key === "bonusDataList") {
                    //         formData.append(key, JSON.stringify(value));
                    //     } else {
                    //         formData.append(key, value);
                    //     }
                    // });
                Object.entries(employeeData).forEach(([key, value]) => {
                if (key !== 'basicPayAnnual' && key !== 'basicPayMonthly' &&
                    key !== 'hraAnnual' && key !== 'hraMonthly' &&
                    key !== 'transportAllowanceAnnual' && key !== 'transportAllowanceMonthly' &&
                    key !== 'otherAllowanceAnnual' && key !== 'otherAllowanceMonthly' &&
                    key !== 'reimbursementExpenseAnnual' && key !== 'reimbursementExpenseMonthly' &&
                    key !== 'variablePayAnnual' && key !== 'variablePayMonthly' &&
                    key !== 'ctcAnnual' && key !== 'ctcMonthly') {
                        // alert(employeeData.updatedDate);
                        if (key === 'updatedDate') {
                            // Check if value is a valid date before converting
                            const dateValue = new Date(value);
                            if (!isNaN(dateValue)) {
                                formData.append(key, dateValue.toISOString());
                            } else {
                                console.error('Invalid date value:', value);
                                formData.append(key, null); // Handle invalid date
                            }
                        } else if (key === 'hikeDataList' || key === 'bonusDataList') {
                            formData.append(key, JSON.stringify(value));
                        } else {
                            formData.append(key, value);
                        }
                }
            });

                    const response = await axios.post(
                        process.env.REACT_APP_IP + "api/financeData",
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `Bearer ${localStorage.getItem('Token')}`,
                            },
                        }
                    );

                    console.log(response);
                    if (response.data === "EmployeeId do not exist") {
                        alert("EmployeeId does not exist.");
                        window.location.reload();
                        setemployeeData({});
                    } else if (response.data === "Error occurred ...") {
                        alert("Error occurred ...");
                        window.location.reload();
                        setemployeeData({});
                    } else {
                        if (isAssociateFinance) {
                            toast.success("Form Submission successful", { position: toast.POSITION.TOP_CENTER });
                            window.location.reload();
                        } else {
                            navigate('/finance');
                        }

                    }


                    // Clear employeeData after successful submission
                    setemployeeData({});
                } catch (error) {
                    // Handle errors
                    console.error('There was a problem with the fetch operation:', error);
                }

            }
        }
        else {
            if (!employeeData?.basicPayAnnual || employeeData?.basicPayAnnual < 0) {
                setError('Basic Pay (Annual) is required and must be greater than 0')
                return;
            }

            if (!employeeData?.basicPayMonthly || employeeData?.basicPayMonthly < 0) {
                setError('Basic Pay (Monthly) is required and must be greater than 0')
                return;
            }
            // Validate hraAnnual
            if (!employeeData?.hraAnnual || employeeData?.hraAnnual < 0) {
                setError('HRA (Annual) is required and must be greater than or equal to 0')
                return;
            }

            // Validate hraMonthly
            if (!employeeData?.hraMonthly || employeeData?.hraMonthly < 0) {
                setError('HRA (Monthly) is required and must be greater than or equal to 0')
                return;
            }

            // Validate specialAllowanceAnnual
            if (!employeeData?.specialAllowanceAnnual || employeeData?.specialAllowanceAnnual < 0) {
                setError('Special Allowance (Annual) is required and must be greater than or equal to 0')
                return;

            }

            // Validate specialAllowanceMonthly
            if (!employeeData?.specialAllowanceMonthly || employeeData?.specialAllowanceMonthly < 0) {
                setError('Special Allowance (Monthly) is required and must be greater than or equal to 0')
                return;
            }
            if (!employeeData?.grossTotalAnnual || employeeData?.grossTotalAnnual < 0) {
                setError('Gross Total (Annual) is required and must be greater than or equal to 0')
                return;
            }
            if (!employeeData?.grossTotalMonthly || employeeData?.grossTotalMonthly < 0) {
                setError('Gross Total (Monthly) is required and must be greater than or equal to 0')
                return;
            }

            // Validate epfAnnual
            if (!employeeData?.epfAnnual || employeeData?.epfAnnual < 0) {
                setError('EPF (Annual) is required and must be greater than or equal to 0')
                return;

            }

            // Validate epfMonthly
            if (!employeeData?.epfMonthly || employeeData?.epfMonthly < 0) {
                setError('EPF (Monthly) is required and must be greater than or equal to 0')
                return;

            }
            if (!employeeData?.ctcAnnual || employeeData?.ctcAnnual < 0) {
                setError('CTC (Annual) is required and must be greater than or equal to 0')
                return;

            }
            if (!employeeData?.ctcMonthly || employeeData?.ctcMonthly < 0) {
                setError('CTC (Monthly) is required and must be greater than or equal to 0')
                return;

            }
            else {

                // setError("")
                // setemployeeData(null)
                try {
                    const formData = new FormData();
                
                    alert(updatespecialAllowanceAnnual +"  updatespecialAllowanceAnnual");
                    alert(updatespecialAllowanceMonthly +"  updatespecialAllowanceMonthly");
                    // Append updated values to formData
                    formData.append('basicPayAnnual', updatedBasicPayAnnual);
                    formData.append('basicPayMonthly', updatedBasicPayMonthly);
                    formData.append('hraAnnual', updatedHraAnnual);
                    formData.append('hraMonthly', updatedHraMonthly);
                    formData.append('specialAllowanceAnnual', updatespecialAllowanceAnnual);
                    formData.append('specialAllowanceMonthly', updatespecialAllowanceMonthly);
                    formData.append('grossTotalAnnual', updatedGrossTotalAnnual);
                    formData.append('grossTotalMonthly', updatedGrossTotalMonthly);
                    formData.append('epfAnnual', updatedEpfAnnual);
                    formData.append('epfMonthly', updatedEpfMonthly);
                    formData.append('ctcAnnual', updatedCtcAnnual);
                    formData.append('ctcMonthly', updatedCtcMonthly);
                
                    // Log FormData contents
                    for (let pair of formData.entries()) {
                        console.log(pair[0] + ': ' + pair[1]);
                    }
                
                    Object.entries(employeeData).forEach(([key, value]) => {
                        if (key !== 'basicPayAnnual' && key !== 'basicPayMonthly' &&
                            key !== 'hraAnnual' && key !== 'hraMonthly' &&
                            key !== 'specialAllowanceAnnual' && key !== 'specialAllowanceMonthly' &&
                            key !== 'grossTotalAnnual' && key !== 'grossTotalMonthly' &&
                            key !== 'epfAnnual' && key !== 'epfMonthly' &&
                            key !== 'ctcAnnual' && key !== 'ctcMonthly') {
                            if (key === 'updatedDate') {
                                // Check if value is a valid date before converting
                                const dateValue = new Date(value);
                                if (!isNaN(dateValue)) {
                                    formData.append(key, dateValue.toISOString());
                                } else {
                                    console.error('Invalid date value:', value);
                                    formData.append(key, null); // Handle invalid date
                                }
                            } else if (key === 'hikeDataList' || key === 'bonusDataList') {
                                formData.append(key, JSON.stringify(value));
                            } else {
                                formData.append(key, value);
                            }
                        }
                    });
                
                    const response = await axios.post(
                        process.env.REACT_APP_IP + "api/financeData",
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `Bearer ${localStorage.getItem('Token')}`,
                            },
                        }
                    );
                
                    console.log(response);
                    if (response.data === "EmployeeId do not exist") {
                        alert("EmployeeId does not exist.");
                        window.location.reload();
                        setemployeeData({});
                    } else if (response.data === "Error occurred ...") {
                        alert("Error occurred ...");
                        window.location.reload();
                        setemployeeData({});
                    } else {
                        if (isAssociateFinance) {
                            toast.success("Form Submission successful", { position: toast.POSITION.TOP_CENTER });
                            window.location.reload();
                        } else {
                            navigate('/finance');
                        }
                    }
                
                    // Clear employeeData after successful submission
                    setemployeeData({});
                } catch (error) {
                    // Handle errors
                    console.error('There was a problem with the fetch operation:', error);
                }

            }

        }

    }

    const downLoadFile = async (employeeId, fileInfo, file, type) => {
        console.log(employeeId);
        console.log(fileInfo);
        console.log(file);

        try {
            setIsLoading(true); // Show the loader
            const bearerToken = localStorage.getItem('Token');
            const jsonData = { fileInfo: fileInfo, file: file, employeeId: employeeId };
            console.log(jsonData);

            fetch(process.env.REACT_APP_IP + `api/getDocument?fileInfo=${fileInfo}&file=${file}&employeeId=${employeeId}`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("Token")}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blobs => {
                    const blob = new Blob([blobs]);
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `${file}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl);
                    setIsLoading(false); // Hide the loader
                })
                .catch(error => {
                    console.error('Error previewing file:', error);
                    setIsLoading(false); // Hide the loader
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false); // Hide the loader
        }
    };


    const handleFileSelect = (e) => {
        console.log("phorjfor", e.target.files)
        const selectedFile = e.target.files[0];
        console.log("selectedFile", selectedFile)
        if (!documentFileTypes.includes(selectedFile.type)) {
            setDocTypeError('Please upload a .pdf, .doc, or .docx file');
            return;
        }
        setemployeeData(prevState => ({
            ...prevState,
            hikeLetter: selectedFile
        }));
        setotherDocuments(selectedFile);
    };

    // const selectedFile = e.target.files[0];
    // const fileSizeLimit = 5 * 1024 * 1024; // 5MB in bytes
    // if (selectedFile.size > fileSizeLimit) {
    //     setotherDocumentsSizeError('Please upload a file below 5MB');
    //     setotherDocuments(null);
    // } else {
    //     setotherDocumentsSizeError('');

    // }


    // };

    const handleFileInputChange = (e) => {
        const fieldName = e.target.name;
        const files = e.target.files;
        setemployeeData(prevState => ({
            ...prevState,
            [fieldName]: files,
        }));
    };


    const uploadClick = (refName) => {
        console.log("uploadClick")
        if (fileInputRefs[refName] && fileInputRefs[refName].current) {
            fileInputRefs[refName].current.click();
        }
    };



    // const handleBonusChange = (date) => {
    //     const list = [...employeeDataList];
    //     console.log("list", list)
    //     list[index]['bonusDate'] = date;
    //     setemployeeBonusList(list);
    //     setemployeeData(prevState => ({
    //         ...prevState,
    //         hikeDataList: list
    //     }));
    // };


    const handleDelete = (id) => {
        setPreviewImages((prevImages) => {
            // Use the filter function to remove the image with the specified id
            return prevImages.filter((image) => image.id !== id);

        });
    };
    const handleInputChange1 = async (event) => {
        const { name, value } = event.target;
        if (value.startsWith('-') || isNaN(value)) {
            return;
        }
    
          const splitValue = value.split(' ')[0];
        setemployeeData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setemployeeDataById((prevState) => ({
            ...prevState,
            [name]: value
        }));
 
        if (name === "employeeId") {
   
            if (value) {
                try {
                    const response = await axiosInstance.get('api/getEmployeeFinanceDataById/' + value);
                    console.log("RESPONSE",response.data) // replace with your actual API endpoint
                    if (response.data && Object.keys(response.data).length > 0) {
                        setemployeeData(response.data);
                        console.log(response.data+" finance data selected");
                        setemployeeDataList(response.data.hikeDataList || []);
                        setemployeeBonusList(response.data.bonusDataList || []);
                        setDocument(response.data.totalDocuments[0]?.hikeLetter || null);
                        setemployeeDataById(response.data);
                    } 
                    else if(employeeDataById.employeeId){
                        try {
                            const response = await axiosInstance.get('api/getEmployeeById/' + value);
                            console.log("RESPONSE1",response.data) // replace with your actual API endpoint
                            setemployeeDataById(response.data)
                        }
                        catch(error){
                            console.log(error)
                        }
                    }
                    else {
                        resetEmployeeData();
                    }
                } catch (error) {
                    handleFetchError(error);
                }
            } else {
                resetEmployeeData();
            }
        }
    };
    

    // const handleCurrencyChange = (name, event) => {
        
    //     const currency = event.target.value;

    //     setemployeeData((prevState) => ({
    //         ...prevState,
    //         [name]: currency
    //     }));
    // };
    
    // const handleCustomCurrencyChange = (key, e) => {

    //     const value = e.target.value;
    //     setemployeeData((prevData) => ({
    //         ...prevData,
    //         [`custom${key.charAt(0).toUpperCase() + key.slice(1)}`]: value
    //     }));
    // };

    const handleCurrencyChange = (name, event) => {
        const currency = event.target.value;
    
        setemployeeData((prevState) => ({
            ...prevState,
            [name]: currency,
            // Reset the custom currency field if "others" is selected
            [`custom${name.charAt(0).toUpperCase() + name.slice(1)}`]: currency === 'others' ? '' : prevState[`custom${name.charAt(0).toUpperCase() + name.slice(1)}`]
        }));
    };
    
    const handleCustomCurrencyChange = (key, e) => {
        const value = e.target.value;
    
        setemployeeData((prevData) => ({
            ...prevData,
            [`custom${key.charAt(0).toUpperCase() + key.slice(1)}`]: value
        }));
    };
    
    


    // const handleCurrencyChange = (name, event) => {
    //     const currency = event.target.value;
    //     setemployeeData((prevState) => ({
    //         ...prevState,
    //         [name]: currency,
    //         [`custom${name.charAt(0).toUpperCase() + name.slice(1)}`]: currency === "others" ? prevState[`custom${name.charAt(0).toUpperCase() + name.slice(1)}`] : ''  // Reset custom currency input if it's not "others"
    //     }));
    // };

    // const handleCustomCurrencyChange = (name, event) => {
    //     const customCurrency = event.target.value;
    //     setemployeeData((prevState) => ({
    //         ...prevState,
    //         [`custom${name.charAt(0).toUpperCase() + name.slice(1)}`]: customCurrency
    //     }));
    // };


    ///
    // const handleCurrencyChange = (field, e) => {
    //     const { value } = e.target;
    //     setemployeeData(prevData => ({
    //         ...prevData,
    //         [field]: value,
    //         customCurrency: value === 'others' ? prevData.customCurrency : ''
    //     }));
    // };
    
    // const handleCustomCurrencyChange = (field, e) => {
    //     const { value } = e.target;
    //     setemployeeData(prevData => ({
    //         ...prevData,
    //         [`custom${field.charAt(0).toUpperCase() + field.slice(1)}`]: value
    //     }));
    // };
    
    


    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        if(value.startsWith('-') || isNaN(value)){
            return;
        }
        const list = [...employeeDataList];
        list[index][name] = value;
        setemployeeDataList(list);
    };

    const handleInputChange2 = (index, event) => {
        const { name, value } = event.target;
        if(value.startsWith('-') || isNaN(value)){
            return;
        }
        const list = [...employeeBonusList];
        list[index][name] = value;
        setemployeeBonusList(list);
    };

    const handleHikeDateChange = (index, date) => {
        const list = [...employeeDataList];
        list[index]['hikeDate'] = date;
        setemployeeDataList(list);
        setemployeeData(prevState => ({
            ...prevState,
            hikeDataList: list
        }));
    };
    const handleBonusDateChange = (index, date) => {
        const list = [...employeeBonusList];
        console.log(list[index]);
        list[index]['bonusDate'] = date;
        setemployeeBonusList(list);
        setemployeeData(prevState => ({
            ...prevState,
            bonusDataList: list
        }));
    };

    const handleAddClick = () => {
        setemployeeDataList([...employeeDataList, { hikeDate: '', hikeAmount: '', hikePercent: '' }]);
    };

    const handleRemoveClick = (index) => {
        const list = [...employeeDataList];
        list.splice(index, 1);
        setemployeeDataList(list);
    };

    const handleAddClickBonus = () => {
        setemployeeBonusList([...employeeBonusList, { bonusDate: '', bonusType: '', bonusAmount: '' }]);
    };

    const handleRemoveClickBonus = (index) => {
        const list = [...employeeBonusList];
        list.splice(index, 1);
        setemployeeBonusList(list);
    };

    const handleBonusChange = (index, date) => {
        const updatedBonusList = employeeBonusList.map((bonus, index) => {
            if (index === 0) { // Assuming you want to update the first item in the list
                return {
                    ...bonus,
                    bonusDate: date || '',
                };
            }
            return bonus;
        });
        setemployeeBonusList(updatedBonusList);
    };

    const handleBonusTypeChange = (index, type) => {
        const updatedBonusList = employeeBonusList.map((bonus, i) => {
            if (index === i) {
                return {
                    ...bonus,
                    bonusType: type,
                };
            }
            return bonus;
        });
        setemployeeBonusList(updatedBonusList);
    };

    const handleBonusAmountChange = (index, amount) => {
        const updatedBonusList = employeeBonusList.map((bonus, i) => {
            if (index === i) {
                return {
                    ...bonus,
                    bonusAmount: amount,
                };
            }
            return bonus;
        });
        setemployeeBonusList(updatedBonusList);
    };

    const handleReset = () => {
        window.location.reload();
    };

    const handleResetPassword = () => {
        navigate("/reset")
    }
    const handleDateChange = (date) => {
        setemployeeData({
            ...employeeData,
            updatedDate: date || null, // Use an empty string if the date is cleared handleBirthDateChange
        });
    }
  

    return (
        <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>

                    <div className="d-flex align-items-center">
                        <button className="btn btn-primary text-white" style={{marginRight : "1rem"}}onClick={handleBack}>
                            Back To Dashboard
                        </button>
                        <div className='' style={{ position: "relative", marginRight: "1rem" }} onClick={handleToggleDropdown}>
                            <FontAwesomeIcon icon={faCircleUser} size="3x" color='#228be6' />
                            {isDropdownOpen && (
                                <div className='d-profile' style={{ position: "absolute", top: "100%", right: 0 }}>
                                    <h6 className="m-1">{userName}</h6>
                                    <span className="reset-password" onClick={handleResetPassword}>Reset password</span>
                                    <Button onClick={handleLogout}>Logout</Button>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </nav>

            <div className="container mt-3" style={{ padding: '20px' }}>
                <div className="card " style={{ boxShadow: "0px 0px 5px #000", borderRadius: "5px" }}>
                    <div className="card-body" style={{ textAlign: 'left' }}>

                        <div className="text-center pt-4  pb-4 bg-primary text-white " style={{ borderRadius: "5px" }}>
                            <h3>Employee Finance Form</h3>
                        </div>
                        {isLoading && (
                            <div className="loader">
                                <div className="circular-loader"></div>
                            </div>
                        )}
                        <span className='d-flex justify-content-center mt-2' style={{ color: 'red' }}>{error}</span>

                        <div className='mt-5 text-left '>
                            <form >

                                <div className="row mb-4">
                                    {/* Employee ID */}
                                    <div className="col-md-6">
                                        <label className="text-left" htmlFor="employeeId">Employee ID <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="_id"
                                            name="_id"
                                            value={employeeData._id}
                                            placeholder="Enter Employee ID"
                                            onChange={handleInputChange1}

                                            required
                                        // style={{
                                        //     backgroundColor: '#f0f0f0',
                                        //     border: '1px solid #ccc',
                                        //     borderRadius: '4px',
                                        //     padding: '5px 10px',
                                        //     cursor: 'not-allowed'
                                        // }}
                                        />

                                        {/* <small className="form-text text-muted">This field is read-only.</small> */}
                                    </div>


                                    {/* Employee Name */}
                                    <div className="col-md-6">
                                        <label htmlFor="employeeName">Employee Name <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="employeeName"
                                            name="employeeName"
                                            value={employeeData.employeeName}
                                            onChange={handleInputChange1}
                                            placeholder="Enter Employee Name"
                                            required
                                        // disabled
                                        // style={{
                                        //     backgroundColor: '#f0f0f0', // Change background color to indicate it's disabled
                                        //     border: '1px solid #ccc',   // Add a border for visual distinction
                                        //     borderRadius: '4px',        // Optional: Rounded corners
                                        //     padding: '5px 10px',        // Optional: Padding for better appearance
                                        //     cursor: 'not-allowed'       // Show 'not-allowed' cursor on hover
                                        // }}
                                        />

                                    </div>
                                </div>

                                {/* Email ID */}
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <label className="text-left" htmlFor="emailId">Email ID <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="emailId"
                                            name="emailId"
                                            value={employeeData.emailId}
                                            onChange={handleInputChange1}
                                            placeholder="Enter Employee ID"
                                            required
                                        />
                                    </div>

                            {/* //updated date */}
                            <div className="col-md-4">
                            <label htmlFor='updatedDate'>Updated Date<span style={{ color: 'red' }}>*</span></label>
                            <div>
                            <DatePicker
                                selected={employeeData.updatedDate}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="yyyy-MM-dd"
                                isClearable
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={15}
                                className='form-control'
                                required
                            />
                            </div>
                        </div>
                                </div>
                                {employeeDataById.country === 'India' && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-left" >Basic Pay<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="basicPayAnnual"
                                                    name="basicPayAnnual"
                                                    value={employeeData?.basicPayAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Basic Pay Per Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="basicPayMonthly"
                                                    name="basicPayMonthly"
                                                    value={employeeData?.basicPayMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Basic Pay Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                            <select className="form-select" value={employeeData?.currency || ''} onChange={(e) => handleCurrencyChange('currency', e)}>
                                                <option value="">Select a currency</option>
                                                <option value="dirham">Dirham</option>
                                                <option value="rupees">Rupees</option>
                                                <option value="dollar">Dollar</option>
                                                <option value="others">Others</option>
                                            </select>
                                            {employeeData.currency === "others" && (
                                                <div>
                                                    <label htmlFor="customCurrency">Enter Other currency</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="department"
                                                        name="departmenty"
                                                        value={employeeData?.customCurrency  || ''}
                                                        onChange={(e) => handleCustomCurrencyChange('currency', e)} // Update the field name
                                                    />
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly">HRA<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="hraAnnual"
                                                    name="hraAnnual"
                                                    value={employeeData?.hraAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter HRA Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="hraMonthly"
                                                    name="hraMonthly"
                                                    value={employeeData?.hraMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter HRA Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency1} onChange={(e) => handleCurrencyChange('currency1', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency1 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency1}
                                                    onChange={(e) => handleCustomCurrencyChange('currency1', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly">Special Allowance<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="specialAllowanceAnnual"
                                                    name="specialAllowanceAnnual"
                                                    value={employeeData?.specialAllowanceAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Special Allowance Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="specialAllowanceMonthly"
                                                    name="specialAllowanceMonthly"
                                                    value={employeeData?.specialAllowanceMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Special Allowance Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select  className="form-select" value={employeeData?.currency2} onChange={(e) => handleCurrencyChange('currency2', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency2 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency2}
                                                    onChange={(e) => handleCustomCurrencyChange('currency2', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly">Gross Total<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="grossTotalAnnual"
                                                    name="grossTotalAnnual"
                                                    value={employeeData.grossTotalAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Gross Total Per Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="grossTotalMonthly"
                                                    name="grossTotalMonthly"
                                                    value={employeeData?.grossTotalMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Gross Total Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency3} onChange={(e) => handleCurrencyChange('currency3', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency3 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency3}
                                                    onChange={(e) => handleCustomCurrencyChange('currency3', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left"> EPF<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            {/* PF Annual */}
                                            <div className="col-md-3">
                                                {/* <label htmlFor="epfAnnual"> PF Annual <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="epfAnnual"
                                                    name="epfAnnual"
                                                    value={employeeData?.epfAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter PF Per Annual"
                                                    required
                                                />
                                            </div>
                                            {/* Employer PF Per Month */}
                                            <div className="col-md-3">
                                                {/* <label className="text-left" htmlFor="epfMonthly">Employer PF Per Month <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="epfMonthly"
                                                    name="epfMonthly"
                                                    value={employeeData?.epfMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter PF Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency4} onChange={(e) => handleCurrencyChange('currency4', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency4 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency4}
                                                    onChange={(e) => handleCustomCurrencyChange('currency4', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>

                                        {/* CTC */}
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly"> CTC<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            {/* CTC Annual */}
                                            <div className="col-md-3">
                                                {/* <label htmlFor="ctcAnnual">CTC Annual <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="ctcAnnual"
                                                    name="ctcAnnual"
                                                    value={employeeData?.ctcAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter CTC Per Annual"
                                                    required
                                                />
                                            </div>
                                            {/* CTC Per Month */}
                                            <div className="col-md-3">
                                                {/* <label className="text-left" htmlFor="ctcMonthly">CTC Per Month <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="ctcMonthly"
                                                    name="ctcMonthly"
                                                    value={employeeData?.ctcMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter CTC Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency5} onChange={(e) => handleCurrencyChange('currency5', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency5 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency5}
                                                    onChange={(e) => handleCustomCurrencyChange('currency5', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {employeeDataById.country === 'Dubai' && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-left" >Basic Pay<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="basicPayAnnual"
                                                    name="basicPayAnnual"
                                                    value={employeeData?.basicPayAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Basic Pay Per Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="basicPayMonthly"
                                                    name="basicPayMonthly"
                                                    value={employeeData?.basicPayMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Basic Pay Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency} onChange={(e) => handleCurrencyChange('currency', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency}
                                                    onChange={(e) => handleCustomCurrencyChange('currency', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly">HRA<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="hraAnnual"
                                                    name="hraAnnual"
                                                    value={employeeData?.hraAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter HRA Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="hraMonthly"
                                                    name="hraMonthly"
                                                    value={employeeData?.hraMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter HRA Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency1} onChange={(e) => handleCurrencyChange('currency1', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency1 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency1}
                                                    onChange={(e) => handleCustomCurrencyChange('currency', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">

                                            <div className="row mt-4">
                                                <div className="col-md-4">
                                                    <label className="text-left" htmlFor="basicPayMonthly">Transport Allowance<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="transportAllowanceAnnual"
                                                        name="transportAllowanceAnnual"
                                                        value={employeeData?.transportAllowanceAnnual}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter transportAllowance Annual"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="transportAllowanceMonthly"
                                                        name="transportAllowanceMonthly"
                                                        value={employeeData?.transportAllowanceMonthly}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter transportAllowance Per Month"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-select" value={employeeData?.currency3} onChange={(e) => handleCurrencyChange('currency3', e)}>
                                                    <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                    </select>
                                                    {employeeData.currency3 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency3}
                                                    onChange={(e) => handleCustomCurrencyChange('currency3', e)}
                                                />
                                            </div>
                                        )}
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-4">
                                                    <label className="text-left" htmlFor="basicPayMonthly">Other Allowance<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="otherAllowanceAnnual"
                                                        name="otherAllowanceAnnual"
                                                        value={employeeData?.otherAllowanceAnnual}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter Other Allowance Per Annual"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="otherAllowanceMonthly"
                                                        name="otherAllowanceMonthly"
                                                        value={employeeData?.otherAllowanceMonthly}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter Other Allowance Per Month"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-select" value={employeeData?.currency4} onChange={(e) => handleCurrencyChange('currency4', e)}>
                                                    <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                    </select>
                                                    {employeeData.currency4 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency4}
                                                    onChange={(e) => handleCustomCurrencyChange('currency4', e)}
                                                />
                                            </div>
                                        )}
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-4">
                                                    <label className="text-left">Reimbursement of Expense<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                {/* PF Annual */}
                                                <div className="col-md-3">
                                                    {/* <label htmlFor="epfAnnual"> PF Annual <span style={{ color: 'red' }}>*</span></label> */}
                                                    <input autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="reimbursementExpenseAnnual"
                                                        name="reimbursementExpenseAnnual"
                                                        value={employeeData?.reimbursementExpenseAnnual}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter ReimbursementExpense Per Annual"
                                                        required
                                                    />
                                                </div>
                                                {/* Employer PF Per Month */}
                                                <div className="col-md-3">
                                                    {/* <label className="text-left" htmlFor="epfMonthly">Employer PF Per Month <span style={{ color: 'red' }}>*</span></label> */}
                                                    <input autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="reimbursementExpenseMonthly"
                                                        name="reimbursementExpenseMonthly"
                                                        value={employeeData?.reimbursementExpenseMonthly}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter reimbursementExpense Per Month"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-select" value={employeeData?.currency6} onChange={(e) => handleCurrencyChange('currency6', e)}>
                                                    <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                    </select>
                                                    {employeeData.currency6 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency6}
                                                    onChange={(e) => handleCustomCurrencyChange('currency6', e)}
                                                />
                                            </div>
                                        )}
                                                </div>
                                            </div>

                                            {/* CTC */}
                                            <div className="row mt-4">
                                                <div className="col-md-4">
                                                    <label className="text-left" htmlFor="basicPayMonthly">Variable pay<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                {/* CTC Annual */}
                                                <div className="col-md-3">
                                                    {/* <label htmlFor="ctcAnnual">CTC Annual <span style={{ color: 'red' }}>*</span></label> */}
                                                    <input autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="variablePayAnnual"
                                                        name="variablePayAnnual"
                                                        value={employeeData?.variablePayAnnual}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter variablePay Per Annual"
                                                        required
                                                    />
                                                </div>
                                                {/* CTC Per Month */}
                                                <div className="col-md-3">
                                                    {/* <label className="text-left" htmlFor="ctcMonthly">CTC Per Month <span style={{ color: 'red' }}>*</span></label> */}
                                                    <input autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="variablePayMonthly"
                                                        name="variablePayMonthly"
                                                        value={employeeData?.variablePayMonthly}
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter VariablePay Per Month"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <select className="form-select" value={employeeData?.currency2} onChange={(e) => handleCurrencyChange('currency2', e)}>
                                                    <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                    </select>
                                                    {employeeData.currency2 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency2}
                                                    onChange={(e) => handleCustomCurrencyChange('currency2', e)}
                                                />
                                            </div>
                                        )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* CTC */}
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly"> CTC<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            {/* CTC Annual */}
                                            <div className="col-md-3">
                                                {/* <label htmlFor="ctcAnnual">CTC Annual <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="ctcAnnual"
                                                    name="ctcAnnual"
                                                    value={employeeData?.ctcAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter CTC Per Annual"
                                                    required
                                                />
                                            </div>
                                            {/* CTC Per Month */}
                                            <div className="col-md-3">
                                                {/* <label className="text-left" htmlFor="ctcMonthly">CTC Per Month <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="ctcMonthly"
                                                    name="ctcMonthly"
                                                    value={employeeData?.ctcMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter CTC Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency5} onChange={(e) => handleCurrencyChange('currency5', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency5 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency5}
                                                    onChange={(e) => handleCustomCurrencyChange('currency5', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                {employeeDataById.country !== 'India' && employeeDataById.country !== 'Dubai' && (
                                // {employeeDataById.country === 'US' && (
                                    <div>
                                        <div className="row">
                                           
                                            <div className="col-md-4">
                                                <label className="text-left" >Basic Pay<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="basicPayAnnual"
                                                    name="basicPayAnnual"
                                                    value={employeeData?.basicPayAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Basic Pay Per Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="basicPayMonthly"
                                                    name="basicPayMonthly"
                                                    value={employeeData?.basicPayMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Basic Pay Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency || ''} onChange={(e) => handleCurrencyChange('currency', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency || ''}
                                                    onChange={(e) => handleCustomCurrencyChange('currency', e)}
                                                />
                                            </div>
                                        )}
                                                
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly">HRA<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="hraAnnual"
                                                    name="hraAnnual"
                                                    value={employeeData?.hraAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter HRA Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="hraMonthly"
                                                    name="hraMonthly"
                                                    value={employeeData?.hraMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter HRA Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency1} onChange={(e) => handleCurrencyChange('currency1', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency1 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency1}
                                                    onChange={(e) => handleCustomCurrencyChange('currency1', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly">Special Allowance<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="specialAllowanceAnnual"
                                                    name="specialAllowanceAnnual"
                                                    value={employeeData?.specialAllowanceAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Special Allowance Annual"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="specialAllowanceMonthly"
                                                    name="specialAllowanceMonthly"
                                                    value={employeeData?.specialAllowanceMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter Special Allowance Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency2} onChange={(e) => handleCurrencyChange('currency2', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency2 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency2}
                                                    onChange={(e) => handleCustomCurrencyChange('currency2', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                        {/* CTC */}
                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <label className="text-left" htmlFor="basicPayMonthly"> CTC<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            {/* CTC Annual */}
                                            <div className="col-md-3">
                                                {/* <label htmlFor="ctcAnnual">CTC Annual <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="ctcAnnual"
                                                    name="ctcAnnual"
                                                    value={employeeData?.ctcAnnual}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter CTC Per Annual"
                                                    required
                                                />
                                            </div>
                                            {/* CTC Per Month */}
                                            <div className="col-md-3">
                                                {/* <label className="text-left" htmlFor="ctcMonthly">CTC Per Month <span style={{ color: 'red' }}>*</span></label> */}
                                                <input autocomplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    id="ctcMonthly"
                                                    name="ctcMonthly"
                                                    value={employeeData?.ctcMonthly}
                                                    onChange={handleInputChange1}
                                                    placeholder="Enter CTC Per Month"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-select" value={employeeData?.currency5} onChange={(e) => handleCurrencyChange('currency5', e)}>
                                                <option value="">Select a currency</option>
                                                    <option value="dirham">Dirham</option>
                                                    <option value="rupees">Rupees</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {employeeData.currency5 === "others" && (
                                            <div>
                                                <label htmlFor="others">Enter Other currency</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData?.customCurrency5}
                                                    onChange={(e) => handleCustomCurrencyChange('currency5', e)}
                                                />
                                            </div>
                                        )}
                                            </div>
                                        </div>
                                    </div>
                                    
                                )}





                                {/* Hike details */}
                                {/* Hike Details Label */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="text-left"></label>
                                    </div>
                                </div>
                                {employeeDataList.length === 0 ? (
    <div className="row mb-2">
        {/* Hike Date */}
        <div className="col-md-4">
            <label htmlFor={`hikeDate-0`}>Hike Date</label>
            <div>
                <DatePicker
                    id={`hikeDate-0`}
                    selected={null}
                    onChange={(date) => handleHikeDateChange(0, date)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    isClearable={false}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    className="form-control"
                />
            </div>
        </div>

        {/* Hike Amount */}
        <div className="col-md-3 mt-4 text-center">
            <input
                autoComplete="off"
                type="text"
                className="form-control"
                id={`hikeAmount-0`}
                name="hikeAmount"
                value={''}
                onChange={(event) => handleInputChange(0, event)}
                placeholder="Enter Hike Amount"
            />
        </div>

        {/* % of Hike */}
        <div className="col-md-3 mt-4">
            <input
                autoComplete="off"
                type="text"
                className="form-control"
                id={`hikePercent-0`}
                name="hikePercent"
                value={''}
                onChange={(event) => handleInputChange(0, event)}
                placeholder="Enter % Of Hike"
            />
        </div>

        {/* Plus Icon */}
        <div className="col-md-1 mt-4">
            <IoIosAdd className="plus-icon" size={20} onClick={handleAddClick} style={{ cursor: 'pointer' }} />
        </div>
    </div>
) : (
    employeeDataList.map((hike, index) => (
        <div key={index} className="row mb-2">
            {/* Hike Date */}
            <div className="col-md-4">
                <label htmlFor={`hikeDate-${index}`}>Hike Date</label>
                <div>
                    <DatePicker
                        id={`hikeDate-${index}`}
                        selected={hike.hikeDate ? new Date(hike.hikeDate) : null}
                        onChange={(date) => handleHikeDateChange(index, date)}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select Date"
                        isClearable={!!hike.hikeDate}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-control"
                    />
                </div>
            </div>

            {/* Hike Amount */}
            <div className="col-md-3 mt-4 text-center">
                <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id={`hikeAmount-${index}`}
                    name="hikeAmount"
                    value={hike.hikeAmount || ''}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Enter Hike Amount"
                />
            </div>

            {/* % of Hike */}
            <div className="col-md-3 mt-4">
                <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id={`hikePercent-${index}`}
                    name="hikePercent"
                    value={hike.hikePercent || ''}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Enter % Of Hike"
                />
            </div>

            {/* Plus Icon */}
            {index === 0 && (
                <div className="col-md-1 mt-4">
                    <IoIosAdd className="plus-icon" size={20} onClick={handleAddClick} style={{ cursor: 'pointer' }} />
                </div>
            )}

            {/* Minus Icon */}
            {index > 0 && (
                <div className="col-md-1 mt-4">
                    <IoRemove className="minus-icon" size={20} onClick={() => handleRemoveClick(index)} style={{ cursor: 'pointer' }} />
                </div>
            )}
        </div>
    ))
)}


                                <div className="row">
                                    {/* </div> */}
                                </div>
                                {employeeBonusList.length === 0 ? (
    <div className="row mb-2">
        {/* Bonus Date */}
        <div className="col-md-4">
            <label htmlFor={`bonusDate-0`}>Bonus Date</label>
            <div>
                <DatePicker
                    id={`bonusDate-0`}
                    selected={null}
                    onChange={(date) => handleBonusDateChange(0, date)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    isClearable={false}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    className="form-control"
                />
            </div>
        </div>

        {/* Bonus Type */}
        <div className="col-md-3 mt-4 text-center">
            <input
                autoComplete="off"
                type="text"
                className="form-control"
                id={`bonusType-0`}
                name="bonusType"
                value={''}
                onChange={(event) => handleInputChange2(0, event)}
                placeholder="Enter Type of Bonus"
            />
        </div>

        {/* Bonus Amount */}
        <div className="col-md-3 mt-4">
            <input
                autoComplete="off"
                type="text"
                className="form-control"
                id={`bonusAmount-0`}
                name="bonusAmount"
                value={''}
                onChange={(event) => handleInputChange2(0, event)}
                placeholder="Enter Bonus Amount"
            />
        </div>

        {/* Plus Icon */}
        <div className="col-md-1 mt-4">
            <IoIosAdd className="plus-icon" size={20} onClick={handleAddClickBonus} style={{ cursor: 'pointer' }} />
        </div>
    </div>
) : (
    employeeBonusList.map((bonus, index) => (
        <div key={index} className="row mb-2">
            {/* Bonus Date */}
            <div className="col-md-4">
                <label htmlFor={`bonusDate-${index}`}>Bonus Date</label>
                <div>
                    <DatePicker
                        id={`bonusDate-${index}`}
                        selected={bonus.bonusDate ? new Date(bonus.bonusDate) : null}
                        onChange={(date) => handleBonusDateChange(index, date)}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select Date"
                        isClearable={!!bonus.bonusDate}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-control"
                    />
                </div>
            </div>

            {/* Bonus Type */}
            <div className="col-md-3 mt-4 text-center">
                <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id={`bonusType-${index}`}
                    name="bonusType"
                    value={bonus.bonusType || ''}
                    onChange={(event) => handleInputChange2(index, event)}
                    placeholder="Enter Type of Bonus"
                />
            </div>

            {/* Bonus Amount */}
            <div className="col-md-3 mt-4">
                <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id={`bonusAmount-${index}`}
                    name="bonusAmount"
                    value={bonus.bonusAmount || ''}
                    onChange={(event) => handleInputChange2(index, event)}
                    placeholder="Enter Bonus Amount"
                />
            </div>

            {/* Plus Icon */}
            {index === 0 && (
                <div className="col-md-1 mt-4">
                    <IoIosAdd className="plus-icon" size={20} onClick={handleAddClickBonus} style={{ cursor: 'pointer' }} />
                </div>
            )}

            {/* Minus Icon */}
            {index > 0 && (
                <div className="col-md-1 mt-4">
                    <IoRemove className="minus-icon" size={20} onClick={() => handleRemoveClickBonus(index)} style={{ cursor: 'pointer' }} />
                </div>
            )}
        </div>
    ))
)}


                                {/* Bonus Date */}
                                {/* <div className="col-md-4"> */}
                                {/* <label htmlFor="bonusDate" >
                                    Bonus Date <span style={{ color: 'red' }}>*</span>
                                        </label> */}
                                {/* <div>
                                            <DatePicker
                                                placeholderText="Select Date"
                                                selected={employeeData?.bonusDate}
                                                onChange={(date) => handleBonusChange(date)}
                                                dateFormat="yyyy-MM-dd"
                                                isClearable={employeeData?.bonusDate}
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                                className="form-control" // Apply appropriate styling
                                            />

                                        </div>
                                    </div> */}

                                {/* Bonus Type */}
                                {/* <div className="col-md-4 text-center">
                                        {/* <label htmlFor="hikeAmount">Hike Amount <span style={{ color: 'red' }}>*</span></label> */}
                                {/*    <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="bonusType"
                                            name="bonusType"
                                            value={employeeData?.bonusType}
                                            onChange={handleInputChange1}
                                            placeholder="Enter Type of Bonus"
                                            required
                                        /> */}
                                {/* </div>  */}

                                {/* Bonus Amount */}
                                {/* <div className="col-md-4">

                                           <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="bonusAmount"
                                            name="bonusAmount"
                                            value={employeeData?.bonusAmount}
                                            onChange={handleInputChange1}
                                            placeholder="Enter Bonus Amount"
                                            required
                                        />
                                    </div> */}


                                <div className='col-md-4 mt-2 mb-4'>
                                    <label>Other Documents</label>

                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                        {/* Display the image */}
                                        {documents ? (
                                            documents.map((doc, index) => (
                                                <div key={index}>
                                                    <a href={doc.s3Url} download={doc.fileName} style={{ width: '1px' }}>{doc.fileName}</a>
                                                </div>
                                            ))
                                        ) : null}
                                        <div className='col-md-3'>
                                            {/* Download link */}
                                            {documents ? (
                                                documents.map((doc, index) => (
                                                    <div key={index} >
                                                        <MdOutlineFileDownload size={30} onClick={() => {
                                                            downLoadFile(employeeData.employeeId, "hikeLetter", doc.fileName)
                                                        }}>
                                                            Download
                                                        </MdOutlineFileDownload >
                                                    </div>
                                                ))
                                            ) : null}
                                        </div>
                                        <input autocomplete="off"
                                            type="file"
                                            accept=".pdf,.doc,.docx"
                                            style={{ display: 'none' }} // Ensure this style doesn't conflict with visibility
                                            onChange={handleFileSelect}
                                            ref={fileInputRefs['hikeLetter']} // Ensure the ref is correctly assigned
                                        />


                                        <button type="button" onClick={() => uploadClick('hikeLetter')} className="btn bg-primary text-white">
                                            + Upload
                                        </button>
                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                            {otherDocumentsSizeError && <p>{otherDocumentsSizeError}</p>}
                                            {docTypeError && <p>{docTypeError}</p>}
                                        </span>
                                    </div>
                                    {/* Display Uploaded otherDocuments */}
                                    {otherDocuments ? (
                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{otherDocuments.name}</p>
                                            <span onClick={() => {
                                                setotherDocuments(null);
                                                if (fileInputRefs.hikeLetter.current) {
                                                    fileInputRefs.hikeLetter.current.value = '';
                                                }
                                            }}>
                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className='row pt-3'>
                                    <div className='col-md-6'>
                                        <button type="button" className="btn btn-block bg-primary text-white" style={{ margin: "5px" }} onClick={handleSubmit}>Submit</button>
                                    </div>
                                    <div className='col-md-6'>
                                        <button type="button" className="btn btn-block bg-primary text-white" style={{ margin: "5px" }} onClick={handleReset}>Reset</button>
                                    </div>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FinancePage