import React, { useMemo, useState, useEffect } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axios_instance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Flex, Menu, Text, Title } from '@mantine/core';
import { IconUserCircle, IconSend } from '@tabler/icons-react';
import BirthDayReminder from './BirthDayReminder';
import { CiEdit } from "react-icons/ci";
import { MdHistoryToggleOff } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
const Finance = () => {
  const navigate = useNavigate();
  const [selectedColumns, setSelectedColumns] = useState({});

  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [employeeDetailsMain, setEmployeeDetailsMain] = useState([]);
  const [financeMergedData, setFinanceMergedData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    employeeId: true,
    employeeName: true,
    emailId: false,
    dateOfBirth: false,
    designation: false,
    department: false,
    location: false,
    workingType: false,
    primaryTechnicalskills: false,
    secondaryTechnicalskills: false,
    otherTechnicalskills: false,
    dateOfJoining: false,
    exfExperience: false,
    totalExperience: false,
    level: false,
    certifications: false,
    backgroundVerification: false,
    status: false,
    overAllExperience : false,
    ctcAnnual: true,
    grossTotalAnnual : false,
  });
  const isTokenValid = (token) => {
    return token !== null && token !== undefined;
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function mergeData(data1, data2) {
    // Log input data for debugging
    console.log("data1", data1);
    console.log("data2", data2);
  
    // If either input is null, return the other
    if (!data1 || data1.length === 0) return data2 || [];
    if (!data2 || data2.length === 0) return data1;
  
    // Proceed with merging if both datasets exist
    for (const item2 of data2) {
      const foundIndex = data1.findIndex(item1 => item1.employeeId === item2.employeeId);
      console.log("foundIndex", foundIndex);
      
      if (foundIndex !== -1) {
        const item1 = data1[foundIndex];
        console.log("item1", item1);
        
        // Merge properties from item2 to item1 if the property does not exist in item1
        for (const [key, value] of Object.entries(item2)) {
          if (!item1.hasOwnProperty(key)) {
            item1[key] = value;
          }
        }
      }
    }
  
    // Return the merged data1
    return data1;
  }
  


  function setTableData() {

    const selectedRows = table.getSelectedRowModel().rows;
    const selectedCOlumns = table.options.state.columnVisibility;
    const rowData = selectedRows.map((row) => row.original);
    setSelectedColumns(selectedCOlumns);
    setRowData(rowData);

  }

  const role = localStorage.getItem('Role');
  const isAdmin = role === 'ADMIN';
  const isSuperAdmin = role === 'SUPER_ADMIN';
  const isAdminFinanace = role === 'ADMIN_FINANCE';
  const isAssociateFinance = role === "ASSOCIATE_FINANCE";
  const isAssociateHR = role === "ASSOCIATE_HR";

  const [userName, setUserName] = useState();

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    // Clear session data
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem("islogged", false);
    window.location.href = "/";

    // Redirect to the login page (replace '/login' with your actual login route)
    // navigate('/login');

  }
  const handleCreateEmployeeClick = () => {
    navigate('/finance');
  }
  useEffect(() => {


    const user = localStorage.getItem('Username');
    setUserName(user)

    
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const token = localStorage.getItem('Token');
        if (!isTokenValid(token)) {
          navigate('/login');
          return;
        }

        setColumnVisibility(prevState => {
          const newState = { ...prevState };
          Object.keys(newState).forEach(key => {
            newState[key] = key === 'employeeId' || key === 'employeeName' || key === 'ctcAnnual';
          });
          return newState;
        });

        const selectedColumns = ['employeeId', 'employeeName','ctcAnnual','emailId']; // Specify your desired columns
        const columnsString = selectedColumns.join(',');
  
        // Use Promise.all to fetch both finance data and employee data
        const [financeResponse, employeeDataResponse] = await Promise.all([
          axiosInstance.get('api/getAllEmployeesFinanceData'),
          fetch(`${process.env.REACT_APP_IP}api/getSelectedColumnsData?columns=${columnsString}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }),
        ]);
        console.log("financeResponse",financeResponse)
        console.log("employeeDataResponse",employeeDataResponse)
        // Check if employeeDataResponse is ok
        if (!employeeDataResponse.ok) {
          throw new Error('Error fetching employee data');
        }
  
        // Get data from responses
        const financeData = financeResponse.data.sort((a, b) =>
         
          a.employeeName.localeCompare(b.employeeName)
        );
        console.log("financeData",financeData)
        const employeeData = await employeeDataResponse.json(); // Parse JSON response
        const mainData = employeeData.sort((a, b) =>
          a.employeeName.localeCompare(b.employeeName) // Sort employee data
        );
  
        setIsLoading(false)
        setEmployeeDetails(financeData);
        setEmployeeDetailsMain(mainData);
        const mergedData = mergeData(financeData, mainData);
        console.log("mergedData",mergedData)
        setFinanceMergedData(mergedData);
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          toast.error("Token Expired .......Please Login again....!")
        } else {
          console.error('Error fetching employee details:', error);
          navigate("/login")
        }
      }
    };

    fetchData();
  }, []);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const table = document.getElementById('classTable');
    const rows = table.getElementsByTagName('tr');
    let printContent = '';
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName('td');
      for (let j = 0; j < cells.length; j++) {
        const header = table.getElementsByTagName('th')[j].textContent;
        const key = header.replace(/[\r\n]+/gm, '').trim();
        const value = cells[j].textContent;
        printContent += `<div class="item"><strong>${key}:</strong> <span>${value}</span></div>`;
      }
      printContent += '<hr>';
    }

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Employee-Finance-Data</title>
          <style>
            /* Add your styles here */
            body {
              font-family: Arial, sans-serif;
            }
            .item {
              margin-bottom: 5px;
            }
            .item strong {
              font-weight: bold;
            }
            hr {
              border: 0;
              border-top: 1px solid #000;
              margin: 10px 0;
            }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Employee ID',
        filterVariant: 'text',
        enableHiding: false,
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },


      },
      {
        accessorKey: 'employeeName',
        header: 'Employee Name',
        filterVariant: 'autocomplete',
        enableHiding: false,
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'emailId',
        header: 'Email ID',
        filterVariant: 'text',
       
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorFn: (row) => {
          const startDate = row && row.dateOfBirth;
          if (startDate && !isNaN(new Date(startDate))) {
            const sDay = new Date(startDate);
            sDay.setHours(0, 0, 0, 0);
            return sDay;
          } else {
            return "NA";
          }
        },
        accessorKey: 'dateOfBirth',
        header: 'Date Of Birth',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!isNaN(new Date(value))) {
            const date = new Date(value);
            return format(date, 'dd-MM-yyyy');
          } else {
            return "NA";
          }
        }
      },

      {
        accessorFn: (row) => {
          const startDate = row && row.dateOfJoining;
          if (startDate && !isNaN(new Date(startDate))) {
            const sDay = new Date(startDate);
            sDay.setHours(0, 0, 0, 0);
            return sDay;
          } else {
            return "NA";
          }
        },
        accessorKey: 'dateOfJoining',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        header: 'Date Of Joining',
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue());
          return format(date, 'dd-MM-yyyy'); // Display only the date
        },
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!isNaN(new Date(value))) {
            const date = new Date(value);
            return format(date, 'dd-MM-yyyy');
          } else {
            return "NA";
          }
        }
      },

      {
        accessorKey: 'designation',
        header: 'Designation',
        filterVariant: 'multi-select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'department',
        header: 'Department',
        filterVariant: 'multi-select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'location',
        header: 'Location',
        filterVariant: 'multi-select',
       
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },

      
      {
        accessorKey: 'primaryTechnicalskills',
        header: 'Primary Technical Skills',
        filterVariant: 'text',
        enableColumnFilterModes: true,
        
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const primaryTechnicalSkillsArray = cell.getValue();
          const formattedTechnicalSkills = Array.isArray(primaryTechnicalSkillsArray)
            ? Array.from(new Set(primaryTechnicalSkillsArray.map(skill => String(skill)))).join(', ')
            : 'N/A'; // Fallback if not an array
      
          return formattedTechnicalSkills;
        },
      },
    
      {
        accessorKey: 'secondaryTechnicalskills',
        header: 'Secondary Technical Skills',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const secondaryTechnicalSkillsArray = cell.getValue();
          const formattedTechnicalSkills = Array.isArray(secondaryTechnicalSkillsArray)
            ? secondaryTechnicalSkillsArray.join(',')
            : '';

          return formattedTechnicalSkills;
        },
      },
      {
        accessorKey: 'otherTechnicalskills',
        header: 'Other Technical Skills',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const otherTechnicalSkillsArray = cell.getValue();
          const formattedTechnicalSkills = Array.isArray(otherTechnicalSkillsArray)
            ? otherTechnicalSkillsArray.join(',')
            : '';

          return formattedTechnicalSkills;
        },
      },
      {
        accessorKey: 'certifications',
        header: 'Certifications',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const certificationsArray = cell.getValue();
          const certificationsSkills = Array.isArray(certificationsArray)
            ? certificationsArray.join(',')
            : '';

          return certificationsSkills;
        },
      },


      {
        accessorKey: 'exfExperience',
        header: 'ExafluenceExperience',
        filterVariant: 'select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        accessorKey: 'overAllExperience',
        header: 'Total Experience',
        filterVariant: 'select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
    
      {
        accessorKey: 'level',
        filterVariant: 'select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        header: 'Level',
      },

      {
        accessorKey: 'backgroundVerification',
        header: 'BackgroundVerification',

        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'boolean',
        Cell: ({ cell }) => (
          <span>{cell.getValue() ? 'Yes' : 'No'}</span>
        ),
      },
      {
        accessorKey: 'workingType',
        header: 'Working Type',
        filterVariant: 'multi-select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'status',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        header: 'Status',
        filterVariant: 'text',
      },

      {
        accessorKey: 'ctcAnnual',
        header: 'CTC (Annual)',
        enableHiding: false,
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        // filterVariant: 'text',
      },
    
      {
        accessorKey: 'grossTotalAnnual',
        header: 'Gross Total (Annual)',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        // filterVariant: 'text',
      },
    

    ],
    [],
  );

  const handleColumnVisibilityChange = (newVisibility) => {
    let updatedVisibility;
  
    if (typeof newVisibility === 'function') {
      // Apply function to get the updated visibility state
      updatedVisibility = newVisibility(columnVisibility);
     
      setColumnVisibility(updatedVisibility);
    } else if (newVisibility && typeof newVisibility === 'object') {
      // Handle the visibility change when newVisibility is an object
      updatedVisibility = newVisibility;
     
      setColumnVisibility(updatedVisibility);
    } else {
      console.error("New visibility is null or not an object:", newVisibility);
      return;
    }
  
    // Extract visible columns based on the updated visibility state
    const selectedColumns = Object.keys(updatedVisibility).filter(column => updatedVisibility[column]);
   
  
    // Format the columns as a comma-separated string
    const columnsString = selectedColumns.join(',');
  
    // Make the API call with the comma-separated string
    axiosInstance.get('api/getSelectedColumnsData', {
      params: { columns: columnsString }
    })
    .then((response) => {
      setEmployeeDetails(response.data);
    })
    .catch((error) => {
      console.error("Error fetching selected column data:", error);
    });
  };

  const table = useMantineReactTable({
    data: financeMergedData,
    columns,
    enableRowSelection: true,
    selectAllMode: 'all',
    initialState: { showColumnFilters: true },
    state: { columnVisibility },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    enableRowActions: true,
    order: 'desc',
    orderBy: 'employeeName',
    showBorder: true,
    styles: { th: { fontWeight: 'bold' } },
    components: { Caption: () => null },
    mantineFilterTextInputProps: { variant: 'filled' },
    columnFilterDisplayMode: 'subheader',
    enableFacetedValues: true,
    renderRowActions: (_id) => (

      <>
        {/* <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(employeeId)}>
            <IconTrash />
          </ActionIcon>
        
          <p>Are you sure you want to delete this employee?</p>
          <Button onClick={closeDeleteConfirmModal}>Cancel</Button>
          <Button color="red" onClick={handleConfirmDelete(employeeId)}>
            Yes
          </Button>
        </Modal> */}
        {/* <Menu.Item icon={<IconUserCircle />} onClick={handleEmployeeClick(employeeDetails.employeeId)}>Profile</Menu.Item> */}
        <div className='d-flex '>
          <CiEdit
            onClick={() => {
              console.log('Navigating with data:', _id.row.original);
              navigate('/financedetails', { state: { data: _id.row.original } });
            }}
            style={{ marginLeft: '10px', marginTop: '12px' }}
            size={20}
          />

          <MdHistoryToggleOff style={{ marginLeft: '10px', marginTop: '12px' }} size={20} onClick={() => {

            navigate(`/finance_history?employee_Id=${_id.row.original.employeeId}`);
          }} />
        </div>
      </>
    ),
  });
  const handleBack = () => {
    navigate("/dashboard")
  }
  const handlecreate = () => {
    navigate("/financedetails")
  }

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const formatExperience = (experience) => {
    if (experience.includes('0 years 1 months')) {
      return experience.replace('0 years 1 months', '1 month').trim();
    } else if (experience.includes('1 months')) {
      return experience.replace('1 months', '1 month');
    } else if (experience.includes('0 years 0 months')) {
      return '0-1 month';
    } else if (experience.includes('0 months')) {
      return experience.replace('0 months', '');
    } else {
      return experience;
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handleExportRows = (rows, table) => {
    const visibleColumns = table.options.state.columnVisibility;
    const trueKeys = Object.keys(visibleColumns).filter(key => visibleColumns[key] === true);
    const selectedRows = table.getSelectedRowModel().rows;
    const rowData = selectedRows.map((row) => row.original);
    const filteredData = rowData.map((obj, index) => {
      const filteredObj = { Number: index + 1 }; // Add rowNumber property
      trueKeys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
          filteredObj[key] = Array.isArray(obj[key]) && obj[key].length === 0 ? 'No data' : obj[key];
        } else {
          filteredObj[key] = 'No data';
        }
      });
      return filteredObj;
    });
    // if (filteredData.length > 0) {
    //   const csv = generateCsv(csvConfig)(filteredData);
    //   download(csvConfig)(csv);
    // } else {
    //   alert("Please Select at Least One Row");
    // }
    if (filteredData.length > 0) {
      let filename;
      if (filteredData.length === 1) {
        const employeeName = filteredData[0].employeeName || 'Employee';
        filename = `${employeeName}`;
      } else {
        filename = 'Employee_Finance_Details';
      }

      const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: filename
      });

      const csv = generateCsv(csvConfig)(filteredData);
      download(csvConfig)(csv);
    } else {
      alert("Please Select at Least One Row");
    }
  };

  const handleResetPassword = () => {
    navigate("/reset")
  }
  const finaceInfo = () => {
    navigate("/financedetails")
  }


  return (
    <div style={{ backgroundColor: '#f2edf3', overflow: 'hidden' }}>
      <nav className="navbar navbar-expand-lg  bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="exalogo.png" alt="Logo" className="logo" />
          </a>
          <h2>
            <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
            <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
            <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
          </h2>

          {/* {!isAssociateFinance &&
            <div style={{ position: 'absolute', right: 200 }}>
              <BirthDayReminder componentName="Finance" />
            </div>
          } */}


          {/* {!isAssociateFinance &&
           
             

            

          } */}



          <div className="d-flex justify-content-end mt-2 align-items-center ">

            {isAssociateFinance ? (
              <button className="btn btn-light text-black" style={{ marginRight: "1rem" }} onClick={handlecreate}>
                Add Financial Data
              </button>) : (
              <div>
                <BirthDayReminder componentName="Finance" />

                <button className="btn btn-light text-black" style={{ marginLeft: "1rem" }} onClick={handleBack}>
                  Back To Dashboard
                </button>
              </div>
            )
            }




            <div className='' style={{ position: "relative", marginRight: "1rem" }} onClick={handleToggleDropdown}>
              <FontAwesomeIcon icon={faCircleUser} size="3x" color='white' />
              {isDropdownOpen && (
                <div className='d-profile' style={{ position: "absolute", top: "100%", right: 0 }}>
                  <h6 className="m-1">{userName}</h6>
                  {/* <span className="reset-password" onClick={handleResetPassword}>Reset password</span> */}
                  <Button onClick={handleLogout}>Logout</Button>
                </div>
              )}
            </div>
          </div>

        </div>
      </nav>
      {/* Employee Details Card */}
      <div className="row mb-3" style={{ maxHeight: 'auto', overflow: 'auto', padding: '30px' }}>
        <div className="col-12 d-flex justify-content-between align-items-center mb-3">
          <h3 className="card-title text-center w-100">Financial Information</h3>
          <button
            className="btn btn-primary"
            onClick={() => {
              finaceInfo();
            }}
            style={{ position: 'absolute', right: '30px' }}
          >
            + Finance Details
          </button>
        </div>
        {/* <button className="btn btn-light text-black" style={{ marginLeft: "1rem" }} onClick={handleBack}>
                  Back To Dashboard
                </button> */}
        <div className="col-md-12">
          {!isLoading ? (
            <div className="card">
              <div className="card-body">

                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        {rowData?.length > 0 ? <h5 class="modal-title" id="exampleModalLabel">Download Employee Finance Data Now!</h5> : <h5 class="modal-title" id="exampleModalLabel">Select Employee</h5>}

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style={{ maxHeight: '300px' }} >
                        {rowData?.length > 0 ? (
                          <table id="classTable" className="table table-bordered">
                            <thead>
                              <tr>
                                {Object.keys(selectedColumns)
                                  .filter(column => !column.startsWith('mrt'))
                                  .map(column => (
                                    selectedColumns[column] ? (
                                      <th key={column} className="cell">
                                        {column}
                                      </th>
                                    ) : null
                                  ))}
                              </tr>
                            </thead>
                            <tbody>
                              {rowData.map((user, index) => (
                                <tr key={index}>
                                  {Object.keys(selectedColumns)
                                    .filter(column => !column.startsWith('mrt'))
                                    .map(column => (
                                      selectedColumns[column] ? (
                                        <td key={column} className="cell">
                                          {user[column] ? (
                                            column === 'exfExperience' ? formatExperience(user[column]) :
                                              Array.isArray(user[column]) && user[column].length === 0 ? 'No data' :
                                                (column === 'dateOfJoining' || column === 'dateOfBirth' ? formatDate(user[column]) : user[column].toString())
                                          ) : 'No data'}
                                        </td>
                                      ) : null
                                    ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>Select atleast one Employee to Generate Report</p>
                        )}





                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                        <Button
                          // disabled={
                          //   !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                          // }
                          //only export selected rows
                          onClick={() => handleExportRows(rowData, table)}
                          startIcon={<FileDownloadIcon />}
                          disabled={!Object.keys(rowData).length > 0}
                          data-dismiss="modal"
                        >
                          Download CSV
                        </Button>
                        <Button

                          onClick={() => handlePrint()}

                          disabled={!Object.keys(rowData).length > 0}
                          data-dismiss="modal"
                        >
                          Print
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                
                  <div className="d-flex justify-content-end flex-row" style={{ padding: '10px' }}>
                    <button
                      className="btn btn-primary mt-2"
                      data-toggle="modal"
                      data-target="#exampleModal1"
                      onClick={() => {
                        setTableData();
                      }}
                    >
                      Generate report
                    </button>
                  </div>
               
                  {financeMergedData ? (
                  <div className="row">
                    <div className="col-12" style={{ paddingBottom: "10px" }}>
                      <div>
                        {" "}
                        {/* Adjust the maxHeight as needed */}
                          <MantineReactTable
                            table={table}
                            style={{ marginBottom: "60px" }}
                          />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="loader">
                    <div className="circular-loader"></div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="loader">
              <div className="circular-loader"></div>
            </div>
          )
          }
        </div>

      </div>
    </div>
  )
}

export default Finance
