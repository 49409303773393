// ProfilePage.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axios_instance';
import { CleaningServices } from '@mui/icons-material';
import JSZip from 'jszip';
import Button from 'react-bootstrap/Button';
import './profile.css'
import { MdOutlineFileDownload } from "react-icons/md";
import { IoDownload } from 'react-icons/io5';
import { AiTwotoneDelete } from "react-icons/ai";
import { MdFileDownload } from "react-icons/md";
import { FaHome } from "react-icons/fa";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Typography, Tooltip, Popover } from '@mui/material';
import { CloudUpload, Delete, CloudDownload, CheckCircle, Cancel, Edit, Archive, Print, FileDownload } from '@mui/icons-material'; // Importing icons
import InfoIcon from '@mui/icons-material/Info';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { Document, Packer, Paragraph, TextRun } from 'docx';


const Profile = () => {
  // Define documentsRequired with default values for 'India'
  const location = useLocation();
  // Access setLocationOptions if passed
  const { locationOptions, countryOptions, workingOptions } = location.state || {};


  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [show, setShow] = useState(false);
  const [editedProfile, setEditedProfile] = useState({}); // State to manage edited profile data
  const [contentEditable, setcontentEditable] = useState(false)
  const [profileData, setProfileData] = useState(null);
  const [profileData1, setProfileData1] = useState(null);
  const [profileData2, setProfileData2] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [promotedDate, setPromotedDate] = useState(null);
  const [relocatedDate, setrelocatedDate] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [relievedDate, setRelievedDate] = useState(null);
  const [inactiveDate, setInactiveDate] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImages, setPreviewImages] = useState(false);
  const [isResumeSubmitted, setIsResumeSubmitted] = useState(false);
  const [resumeDetails, setResumeDetails] = useState('');
  const [ndaDetails, setNDADetails] = useState('');
  const [resumeFile, setResumeFile] = useState(null);
  const [ndaFile, setNDAFile] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [Imagedetails, setImagedetails] = useState(null);
  const [EducationalQualifications, setEducationalQualifications] = useState(null);
  const [educationdetails, setEducationdetails] = useState(null);
  const [paySlips, setPaySlips] = useState(null);
  const [paySlipsdetails, setPaySlipsdetails] = useState(null);
  const [isVisibles, setIsVisibles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const [editMode, setEditMode] = useState({});
  const [employeeId, setEmployeeID] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [documentsRequired, setDocumentsRequired] = useState({
    'profilePhoto': true,
    'resume': true,
    'nda': true,
    'educationalDocuments': true,
    'paySlips': true,
    'otherDocuments': true,
    'backgroundVerificationDoc': true
  });

  const updateDocumentsRequired = (selectedCountry) => {

    switch (selectedCountry) {
      case 'Canada':
        setDocumentsRequired({
          'profilePhoto': true,
          'resume': true,
          'nda': false,
          'educationalDocuments': true,
          'paySlips': true,
          'otherDocuments': true,
          'workVisa': true,
          'workPermit': true,
          'residanceVisa': true,
          'labourCard': true
        });
        break;
      case 'Dubai':
        setDocumentsRequired({
          'profilePhoto': true,
          'resume': true,
          'nda': false,
          'educationalDocuments': true,
          'paySlips': true,
          'otherDocuments': true,
          'workVisa': true,
          'workPermit': true,
          'residanceVisa': true,
          'emriatesID': true,
          'labourCard': true
        });
        break;
      case 'Us':
        setDocumentsRequired({
          'profilePhoto': true,
          'resume': true,
          'nda': false,
          'educationalDocuments': true,
          'paySlips': true,
          'otherDocuments': true,
          'passportCopy': true,
          'i_797': true,
          'i_94Card': true,
          'experienceLetter': true,
          'relievingLetter': true,
          'drivingLicense': true,
          'ssnNumber': true,
          'h4Copy': true,
          'gcCard': true
        });
        break;
      case 'India':
      default:
        setDocumentsRequired({
          'profilePhoto': true,
          'resume': true,
          'nda': true,
          'educationalDocuments': true,
          'paySlips': true,
          'otherDocuments': true
        });
        break;
    }
  };
  const validateName = (value) => {
    if (!namePattern.test(value)) {
      console.warn('Only letters and spaces are allowed in employeeName');
      return false;
    }
    return true;
  };

  const toggleVisibility = () => {
    setIsVisibles(!isVisibles);
  };
  const role = localStorage.getItem('Role');
  const isAdminFinanace = role === 'ADMIN_FINANCE';
  const isAdmin = role === 'ADMIN';
  const isSuperAdmin = role === 'SUPER_ADMIN';
  const isAssociateHR = role === 'ASSOCIATE_HR';
  const isAssociateFinance = role === 'ASSOCIATE_FINANCE';

  const navigate = useNavigate()
  const fileInputRefs = {
    nda: useRef(null),
    educationalDocuments: useRef(null),
    paySlips: useRef(null),
    profilePhoto: useRef(null),
    resume: useRef(null),
    otherDocuments: useRef(null),
    // dubai
    workVisa: useRef(null),
    workPermit: useRef(null),
    residanceVisa: useRef(null),
    emriatesID: useRef(null),
    labourCard: useRef(null),
    // USA
    gcCard: useRef(null),
    passportCopy: useRef(null),
    i_797: useRef(null),
    i_94Card: useRef(null),
    experienceLetter: useRef(null),
    relievingLetter: useRef(null),
    drivingLicense: useRef(null),
    ssnNumber: useRef(null),
    h4Copy: useRef(null),
    backgroundVerificationDoc: useRef(null),

  };

  const handleEditClick = () => {
    // Toggle global edit mode
    setIsEditMode((prevMode) => !prevMode);
  };

  // Check if any document type is in edit mode
  const isAnyEditMode = Object.values(editMode).some((isEdit) => isEdit);

  const handleDownload = async (employeeId, fileInfo, file, type) => {
    console.log(employeeId);
    console.log(fileInfo);
    console.log(file);

    try {
      setIsLoading(true); // Show the loader
      const bearerToken = localStorage.getItem('Token');
      const jsonData = { fileInfo: fileInfo, file: file, employeeId: employeeId };
      console.log(jsonData);

      fetch(process.env.REACT_APP_IP + `api/getDocument?fileInfo=${fileInfo}&file=${file}&employeeId=${employeeId}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("Token")}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blobs => {
          const blob = new Blob([blobs]);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${file}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobUrl);
          setIsLoading(false); // Hide the loader
        })
        .catch(error => {
          console.error('Error previewing file:', error);
          setIsLoading(false); // Hide the loader
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false); // Hide the loader
    }
  };


  const downLoadFile = async (employeeId, fileInfo, file) => {
    try {
      const bearerToken = localStorage.getItem('Token');
      const response = await fetch(process.env.REACT_APP_IP + `api/getDocument?fileInfo=${fileInfo}&file=${file}&employeeId=${employeeId}`, {
        headers: { "Authorization": `Bearer ${bearerToken}` }
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      return response.blob();
    } catch (error) {
      console.error('Error fetching file:', error);
      throw error;
    }
  };


  const handleDownloadAll = async () => {
    if (!selectedEmployee || !selectedEmployee.totalDocuments || selectedEmployee.totalDocuments.length === 0) {
      alert("No documents to download");
      return;
    }

    setIsLoading(true);
    const zip = new JSZip();
    const folder = zip.folder(selectedEmployee.employeeName || 'Documents');

    for (let docIndex = 0; docIndex < selectedEmployee.totalDocuments.length; docIndex++) {
      const document = selectedEmployee.totalDocuments[docIndex];
      const docType = Object.keys(document)[0];
      const docDetails = document[docType];

      for (let innerIndex = 0; innerIndex < docDetails.length; innerIndex++) {
        const doc = docDetails[innerIndex];

        try {
          const blob = await downLoadFile(selectedEmployee.employeeId, docType, doc.fileName);
          folder.file(doc.fileName, blob);
        } catch (error) {
          console.error(`Error downloading ${doc.fileName}:`, error);
        }
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${selectedEmployee.employeeName || 'Employee'}_Documents.zip`);
      setIsLoading(false);
    });
  };





  const handleDelete = async (employeeId, fileType, event) => {
    event.stopPropagation();
    setIsVisible(false)
    setIsLoading(true)
    try {
      // Make an HTTP request to the backend to delete the file
      const response = await axios.post(process.env.REACT_APP_IP + 'api/deleteDocument', null, {
        params: {
          fileInfo: fileType,
          employeeId: employeeId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Token')}`,
        },
      }
      );

      // Check the response from the server
      if (response.status === 200) {
        // Update state or perform any additional actions if needed
        setIsLoading(false)
        console.log('File deleted successfully');
        toast.success("File deleted successfully", { position: toast.POSITION.TOP_CENTER })
        window.location.reload()

      } else {
        console.error('Failed to delete file');
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error deleting file:', error);
    }

  };
  // for education documents 
  const handleDeleteFile = (id) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setUploadFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const handleDeleteHistory = async (employeeId, indexType, index) => {
    setIsLoading(true); // Start loading

    try {
      // Make an HTTP request to the backend to delete the history entry
      const response = await axios.post(process.env.REACT_APP_IP + 'history/deleteHistorydetails', null, {
        params: {
          employeeID: employeeId, // Corrected to match your backend expectations
          historyType: indexType,
          rowIndex: index,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Token')}`,
        },
      });

      // Check the response from the server
      if (response.status === 200) {
        // Update state or perform any additional actions if needed
        console.log('History entry deleted successfully');
        toast.success("History entry deleted successfully", { position: toast.POSITION.TOP_CENTER });
        // Optionally, refresh or re-fetch the data instead of reload
        window.location.reload()
      } else {
        console.error('Failed to delete history entry');
      }
    } catch (error) {
      console.error('Error deleting history entry:', error);
      toast.error("Error deleting history entry", { position: toast.POSITION.TOP_CENTER });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };


  const handleUpload = async (docType, file) => {
    setIsLoading(true);
    console.log("storedEmployee _ handle upload", employeeId, "fileName", file)
    const formData = new FormData();
    formData.append('fileInfo', docType);
    formData.append("fileName", file);
    formData.append('employeeId', employeeId);



    // Check file type for profile photo
    const allowedImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    // Restrict file types only for the profile photo
    if (docType === 'profilePhoto' && !allowedImageTypes.includes(file.type)) {
      setIsLoading(false);
      toast.error("Invalid file format for profile photo. Only PNG and JPG images are allowed.", { position: toast.POSITION.TOP_CENTER });
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_IP + 'api/uploadDocument', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('Token')}`,
        },
      });

      console.log("response", response.data);
      if (response.status === 200) {
        // Update state or perform any additional actions if needed
        setIsLoading(false);
        console.log('Uploaded Successfully');
        toast.success("Uploaded Successfully", { position: toast.POSITION.TOP_CENTER });
        window.location.reload()

        // Fetch updated documents if needed
        // fetchDocuments(selectedEmployee.employeeId); // Adjust as necessary
      } else {
        console.error('Failed to upload document');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      setIsLoading(false);
      toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
    }
  };

  const handleDownloadFormat = (format) => {


    // Determine the columns to download based on edit mode
    const columns = isEditMode
      ? ['Documents List', 'Provided Documents', 'Document Name'] // If in edit mode, include Document Name
      : ['Documents List', 'Provided Documents']; // If not in edit mode, exclude Document Name

    if (format === 'excel') {
      // Excel download logic
      const workbook = XLSX.utils.book_new();
      const worksheetData = [columns]; // Start with the header row

      selectedEmployee.totalDocuments.forEach((document) => {
        const docType = Object.keys(document)[0];
        const docDetails = document[docType];
        const providedDocuments = docDetails.length > 0 ? 'Yes' : 'No';

        // Prepare data row
        const row = [docType, providedDocuments];
        if (isEditMode) {
          row.push(docDetails.length > 0 ? docDetails.map(doc => doc.fileName).join(', ') : ''); // Assuming each document has a name property
        }
        worksheetData.push(row);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Documents');
      XLSX.writeFile(workbook, `documents_${new Date().toISOString().split('T')[0]}.xlsx`);

    } else if (format === 'pdf') {
      // PDF download logic
      const doc = new jsPDF();
      doc.text('Documents List', 10, 10);

      let y = 20; // Starting y position for text
      selectedEmployee.totalDocuments.forEach((document) => {
        const docType = Object.keys(document)[0];
        const docDetails = document[docType];
        const providedDocuments = docDetails.length > 0 ? 'Yes' : 'No';

        let line = `${docType}: ${providedDocuments}`;
        if (isEditMode) {
          line += ` - Document Name: ${docDetails.length > 0 ? docDetails.map(doc => doc.name).join(', ') : ''}`;
        }

        doc.text(line, 10, y);
        y += 10; // Increment y position for next line
      });

      doc.save(`documents_${new Date().toISOString().split('T')[0]}.pdf`);

    } else {
      console.log(`Unsupported format: ${format}`);
    }
  };

  const handleHistoryDownload = (format) => {
    // Determine the columns to download based on edit mode
    const columns =
      ['Designation', 'Promoted Date', 'Location', 'Relocated Date', 'Employee Status', 'Relieved Date', 'Status', 'Inactive Date', 'Inactive Period', 'Inactive Reason'] // Include Actions if in edit mode


    if (format === 'excel') {
      // Excel download logic
      const workbook = XLSX.utils.book_new();
      const worksheetData = [columns]; // Start with the header row

      historyData.forEach((entry) => {
        // Prepare data row
        const row = [
          entry.designation,
          formatDate(entry.promotedDate),
          entry.location,
          formatDate(entry.relocatedDate),
          entry.employeeStatus,
          formatDate(entry.relievedDate),
          entry.status,
          formatDate(entry.inActiveDate),
          entry.inActivePeriod,
          entry.inActiveReason,
        ];

        worksheetData.push(row);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'History');
      XLSX.writeFile(workbook, `history_${new Date().toISOString().split('T')[0]}.xlsx`);

      // } else if (format === 'pdf') {
      //   // PDF download logic
      //   const doc = new jsPDF();
      //   doc.text('History List', 10, 10);

      //   let y = 20; // Starting y position for text
      //   historyData.forEach((entry) => {
      //     const line = `Designation: ${entry.designation}, Promoted Date: ${formatDate(entry.promotedDate)}, Location: ${entry.location}, Relocated Date: ${formatDate(entry.relocatedDate)}, Employee Status: ${entry.employeeStatus}, Relieved Date: ${formatDate(entry.relievedDate)}, Status: ${entry.status}, Inactive Date: ${formatDate(entry.inActiveDate)}, Inactive Period: ${entry.inActivePeriod}, Inactive Reason: ${entry.inActiveReason}`;

      //     doc.text(line, 10, y);
      //     y += 10; // Increment y position for next line
      //   });

      //   doc.save(`history_${new Date().toISOString().split('T')[0]}.pdf`);

    } else {
      console.log(`Unsupported format: ${format}`);
    }
  };



  const documentFileTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  // const handleFileSelect = (e, fileType) => {
  //   const file = e.target.files[0];

  //   // const allowedFileTypes = {
  //   //   profilePhoto: ['image/png', 'image/jpg', 'image/jpeg'],
  //   //   resume: documentFileTypes,
  //   //   nda: documentFileTypes,
  //   //   educationalDocuments: documentFileTypes,
  //   //   paySlips: documentFileTypes,
  //   //   otherDocuments: documentFileTypes,
  //   //   workVisa: documentFileTypes,
  //   //   workPermit: documentFileTypes,
  //   //   residanceVisa: documentFileTypes,
  //   //   emriatesID: documentFileTypes,
  //   //   labourCard: documentFileTypes,
  //   //   gcCard: documentFileTypes,
  //   //   passportCopy: documentFileTypes,
  //   //   i_797: documentFileTypes,
  //   //   i_94Card: documentFileTypes,
  //   //   experienceLetter: documentFileTypes,
  //   //   relievingLetter: documentFileTypes,
  //   //   drivingLicense: documentFileTypes,
  //   //   ssnNumber: documentFileTypes,
  //   //   h4Copy: documentFileTypes,
  //   //   backgroundVerificationDoc: documentFileTypes,

  //   // };

  //   // Check if the fileType is valid and has allowed file types defined
  //   if (!allowedFileTypes[fileType]) {
  //     alert(`Invalid document type: ${fileType}.`);
  //     return;
  //   }

  //   // Check if the selected file type is allowed
  //   if (!allowedFileTypes[fileType].includes(file.type)) {
  //     alert(`Invalid file type for ${fileType}. Please upload a valid file.`);
  //     return;
  //   }

  //   // Check if the file already exists only for profile photo, resume, and nda
  //   if (fileType === 'profilePhoto' || fileType === 'Resume' || fileType === 'nda') {
  //     const existingFiles = selectedEmployee.totalDocuments.find(doc => Object.keys(doc)[0] === fileType);
  //     if (existingFiles && existingFiles[fileType].length > 0) {
  //       alert(`${fileType} already exists. Please delete the existing file before uploading a new one.`);
  //       return;
  //     }
  //   }

  //   // // Check if the profile photo already exists
  //   // const existingProfilePhotos = selectedEmployee.totalDocuments[1].profilePhoto;
  //   // if (existingProfilePhotos && existingProfilePhotos.length > 0) {
  //   //     // Show an error message or prompt to delete existing file
  //   //     alert('Profile photo already exists. Please delete the existing file before uploading a new one.');
  //   //     return;
  //   // }
  //   // // Check if the Resume already exists
  //   // const existingResumePhotos = selectedEmployee.totalDocuments[2].resume;
  //   // if (existingResumePhotos && existingResumePhotos.length > 0) {
  //   //     // Show an error message or prompt to delete existing file
  //   //     alert('Resume already exists. Please delete the existing file before uploading a new one.');
  //   //     return;
  //   // }
  //   // // Check if the NDA already exists
  //   // const existingNdaPhotos = selectedEmployee.totalDocuments[3].nda;
  //   // if (existingNdaPhotos && existingNdaPhotos.length > 0) {
  //   //     // Show an error message or prompt to delete existing file
  //   //     alert('NDA already exists. Please delete the existing file before uploading a new one.');
  //   //     return;
  //   // }
  //   const storedEmployee = sessionStorage.getItem('EmployeeId');
  //   const formData = new FormData();
  //   formData.append('fileInfo', fileType);
  //   formData.append("fileName", file);
  //   formData.append('employeeId', storedEmployee);

  //   // Save the document details and upload date based on file type
  //   if (fileType === 'Resume') {
  //     setResumeDetails(`${file.name}`);
  //     setResumeFile(file);
  //     setPreviewImages(true)
  //   } else if (fileType === 'nda') {
  //     setNDADetails(`${file.name}`);
  //     setNDAFile(file);
  //   } else if (fileType === 'profilePhoto') {
  //     setImagedetails(`${file.name}`);
  //     setSelectedImage(file);
  //   }
  //   else if (fileType === 'educationalDocs') {
  //     setEducationdetails(`${file.name}`);
  //     setEducationalQualifications(file);
  //     setUploadedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  //     const selectedFiles = Array.from(e.target.files).map((file) => ({
  //       id: Date.now(),
  //       name: file.name,
  //       type: file.type,
  //     }));
  //   }
  //   else if (fileType === 'payslips') {
  //     setPaySlipsdetails(`${file.name}`);
  //     setPaySlips(file);
  //     setUploadFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  //     const selectedFiles = Array.from(e.target.files).map((file) => ({
  //       id: Date.now(),
  //       name: file.name,
  //       type: file.type,
  //     }));

  //   }
  //   handleUpload(formData);
  // };


  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleShow = () => {
    console.log('Handle Show called');
    setEditedProfile({ ...selectedEmployee });
    setShow(true);
    setcontentEditable(true);
    setSelectedLocation(selectedEmployee.location || '');
    setIsVisible(!isVisible);
    console.log(show);
  };

  const uploadClick = (documentType) => {

    fileInputRefs[documentType].current.click();
  };

  const DeleteClick = (documentType) => {
    // setPreviewImages((prevImages) => prevImages.filter((image) => image.id !== id));
    console.log("Delete option was clicked")
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Save the document details and upload date
    setResumeDetails(`${file.name}`);

    // Save the uploaded document for further use (display, delete)
    setUploadedDocument(file);
  };

  const handleUploadButtonClick = () => {
    // Trigger the click event of the hidden file input
    fileInputRefs.current.click();
  };


  const handleClose = () => {
    console.log('Handle Close called');
    setcontentEditable(false);
    setShow(false);
  };


  const handleSave = async () => {
    console.log("editedProfile...", editedProfile)
    axiosInstance
      .post("api/updateEmployee", editedProfile)
      .then((response) => {
        // Handle success
        console.log("Update successful:", response.data);

        // Show success toast
        toast.success("Profile successfully updated!", { position: toast.POSITION.TOP_CENTER });
        setShowSuccessModal(true);
        console.log(selectedEmployee.country);
        const updatedEmployee = { ...selectedEmployee, country: editedProfile.country };
        setSelectedEmployee(updatedEmployee);
        updateDocumentsRequired(editedProfile.country)
        window.location.reload();
        // Close the modal after saving changes
        handleClose();
        // window.location.reload();

      })
      .catch((error) => {
        // Handle error
        console.error("Update failed:", error);
        // Show error toast
        toast.error("Failed to update profile. Please try again.", { position: toast.POSITION.TOP_CENTER });
      })

  };
  const namePattern = /^[a-zA-Z\s]*$/;
  const idPattern = /[^a-zA-Z0-9]/g;
  const emailPattern = /^[a-zA-Z]+@exafluence\.com$/;

  const handleInputChange = ({ name, value }) => {


    try {
      if ((name === 'phoneNumber') && /\D/.test(value)) {
        console.warn('Only numbers are allowed in', name);
        return; // Exit the function if non-numeric characters are found
      }

      if (name === "overAllExperience") {
        // Check if the value is not a valid number or decimal
        console.log(typeof (value))
        if (!/^\d+(\.\d+)?$/.test(value)) {
          console.warn('Only numbers are allowed in', name);
          return; // Exit the function if non-numeric characters are found
        }
      }

      if (name === 'country' || name === 'educationalQualification' || name === "department" || name === 'employeeName') {

        if (!namePattern.test(value)) {
          console.warn('Only String are allowed in ', name);
          return; // Exit the function if non-numeric characters are found
        }
      }

      if (name === 'employeeId') {

        if (!idPattern.test(value)) {
          console.warn('Only String are allowed in Country');
          return; // Exit the function if non-numeric characters are found
        }
      }
      if (name === 'emailId') {

        if (!emailPattern.test(value)) {
          console.warn('Only String are allowed in Country');
          return; // Exit the function if non-numeric characters are found
        }
      }

      setEditedProfile((prevProfile) => {
        console.log("check handle input change", name, value)
        if (name === 'promotedDate' || name === 'relocatedDate' || name === 'relievedDate' || name === 'inActiveDate()' || name === 'activeDate' || name === 'salaryDate') {
          return {
            ...prevProfile,
            [name]: value,
          };

        } else if (name === 'certifications' || name === 'primaryTechnicalskills' || name === 'secondaryTechnicalskills' || name === 'otherTechnicalskills') {
          const valuesArray = value.split(',').map((item) => item.trim());
          return {
            ...prevProfile,
            [name]: valuesArray,
          };
        } else if (name === 'bonusType') {
          return {
            ...prevProfile,
            [name]: value,
          };

        } else if (name === 'employeeStatus') {
          console.log("value", value)
          if (value === 'Relieved') {
            setRelievedDate(new Date());
            return {
              ...prevProfile,
              [name]: value,

            };

          } else {
            return {
              ...prevProfile,
              [name]: value,
            }
            // If the input is 'status' and the value is 'Relieved', update the relievedDate state

          };

        }
        else if (name == 'workingType') {
          return {
            ...prevProfile,
            [name]: value,
          };
        }

        else if (name === 'status') {
          console.log("value", value)
          if (value === 'Inactive') {
            setInactiveDate(new Date());
            return {
              ...prevProfile,
              [name]: value,

            };
          } else {
            return {
              ...prevProfile,
              [name]: value,
            }
            // If the input is 'status' and the value is 'Relieved', update the relievedDate state

          };

        } else {
          return {
            ...prevProfile,
            [name]: value,
          };
        }
      });
      setShowSuccessModal(true); // Show the success modal
    } catch (error) {
      console.error('Update failed:', error);
    }


  };
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const printContent = document.getElementById('print-content').innerHTML;

    printWindow.document.open();
    printWindow.document.write(`
            <html>
                <head>
                    <title>Print Profile</title>
                    <!-- Add your styles if needed -->
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);
    printWindow.document.close();

    // Write the HTML content to the new window
    printWindow.print(); // Trigger the print dialog once the content is loaded
    printWindow.close(); // Close the print window after printing

  };

  const handlePrintDoc = () => {
    // Create a new window for printing
    const printWindow = window.open('', '_blank');


    // Start constructing the HTML content
    let htmlContent = `
      <html>
        <head>
          <title>Print Document List</title>
          <style>
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h1 style="text-align: center;">Documents List</h1>
          <table>
            <thead>
              <tr>
                <th>Documents List</th>
                <th>Provided Documents</th>
                ${isEditMode ? '<th>Document Name</th>' : ''}
              </tr>
            </thead>
            <tbody>
    `;

    // Populate table rows based on selectedEmployee data
    selectedEmployee.totalDocuments.forEach((document) => {
      const docType = Object.keys(document)[0];
      const docDetails = document[docType];
      const providedDocuments = docDetails.length > 0 ? 'Yes' : 'No';
      const documentNames = isEditMode ? (docDetails.length > 0 ? docDetails.map(doc => doc.fileName).join(', ') : '') : '';

      htmlContent += `
        <tr>
          <td>${docType}</td>
          <td>${providedDocuments}</td>
          ${isEditMode ? `<td>${documentNames}</td>` : ''}
        </tr>
      `;
    });

    // Close the HTML content
    htmlContent += `
            </tbody>
          </table>
        </body>
      </html>
    `;

    // Write the HTML content to the new window
    printWindow.document.write(htmlContent);
    printWindow.document.close(); // Close the document for writing
    printWindow.onload = function () {
      printWindow.print(); // Trigger the print dialog once the content is loaded
      printWindow.close(); // Close the print window after printing
    };
  };

  const handlePrintHistory = () => {
    // Create a new window for printing
    const printWindow = window.open('', '_blank');

    // Start constructing the HTML content
    let htmlContent = `
      <html>
        <head>
          <title>Print History List</title>
          <style>
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h1 style="text-align: center;">History List</h1>
          <table>
            <thead>
              <tr>
                <th>Designation</th>
                <th>Promoted Date</th>
                <th>Location</th>
                <th>Relocated Date</th>
                <th>Employee Status</th>
                <th>Relieved Date</th>
                <th>Status</th>
                <th>Inactive Date</th>
                <th>Inactive Period</th>
                <th>Inactive Reason</th>
              </tr>
            </thead>
            <tbody>
    `;

    // Populate table rows based on historyData
    historyData.forEach((entry) => {
      htmlContent += `
        <tr>
          <td>${entry.designation}</td>
          <td>${formatDate(entry.promotedDate)}</td>
          <td>${entry.location}</td>
          <td>${formatDate(entry.relocatedDate)}</td>
          <td>${entry.employeeStatus}</td>
          <td>${formatDate(entry.relievedDate)}</td>
          <td>${entry.status}</td>
          <td>${formatDate(entry.inActiveDate)}</td>
          <td>${entry.inActivePeriod}</td>
          <td>${entry.inActiveReason}</td>
        </tr>
      `;
    });

    // Close the HTML content
    htmlContent += `
            </tbody>
          </table>
        </body>
      </html>
    `;

    // Write the HTML content to the new window
    printWindow.document.write(htmlContent);
    printWindow.document.close(); // Close the document for writing
    printWindow.onload = function () {
      printWindow.print(); // Trigger the print dialog once the content is loaded
      printWindow.close(); // Close the print window after printing
    };
  };


  const handleDownloadCSV = () => {
    // Implement CSV download logic here
    // You can use a library like 'jsonexport' or create a custom CSV string
    // For simplicity, let's assume a basic CSV format for selectedEmployee
    const csvData = Object.values(selectedEmployee).join(',');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'profile.csv';
    link.click();
  };

  const handleBack = () => {
    navigate("/dashboard")
  }

  const handleFinancehistory = () => {
    navigate("/finance_history")
  }

  // const formatDate = (dateString) => {
  //     const dateObject = new Date(dateString);
  //     const day = dateObject.getDate().toString().padStart(2, '0');
  //     const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  //     const year = dateObject.getFullYear();

  //     return `${day}/${month}/${year}`;
  //   };

  const handleHistory = () => {
    setIsHistoryVisible(true);
    console.log("employeeId", selectedEmployee.employeeId);

    axiosInstance
      .get("history/employees/" + selectedEmployee.employeeId)
      .then((response) => {
        console.log("response", response.data.workHistory);

        const sortedHistoryData = response.data.workHistory.sort((a, b) =>
          new Date(b.promotedDate) - new Date(a.promotedDate)
        );
        setHistoryData(sortedHistoryData);

        // After setting the history data, scroll to the history section
        const historyElement = document.getElementById("history");
        if (historyElement) {
          historyElement.scrollIntoView({ behavior: "smooth" });
        }
      })
      .catch((error) => {
        console.error("Error fetching history:", error);
      });
  };



  const handleResumeSubmission = (event) => {
    // Assume you have some logic to submit the resume and get details
    // For demonstration purposes, let's say you receive details as a string
    const submittedDetails = 'Resume submitted successfully';

    // Update state
    setIsResumeSubmitted(true);
    setResumeDetails(submittedDetails);
  };

  const convertToDecimal = (yearsMonths) => {
    if (!yearsMonths || yearsMonths.trim() === "0" || yearsMonths.trim().toLowerCase() === "0 years") {
      return "0 years";  // Handle zero experience cases
    }
  
    // Trim the input string
    yearsMonths = yearsMonths.trim();
  
    // Replace 'years' with '.' and remove 'months'
    const result = yearsMonths.replace(/\s*years\s*/g, '.').replace(/months/g, '').trim();

    // Convert the result to a number
    const decimalValue = parseFloat(result).toFixed(2);

    return isNaN(decimalValue) ? "0 years" : decimalValue;
  }

  const renderDocumentsData = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Documents List</th>
            <th>Provided Documents</th>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>

        </tbody>
      </table>
    )
  }



  const renderEmployeeDetails = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Designation</th>
            <th>Promoted Date</th>
            <th>Location</th>
            <th>Relocated Date</th>
            <th>EmployeeStatus</th>
            <th>Relieved Date</th>
            <th>Status</th>
            <th>Inactive / active Date</th>
            <th>Inactive Duration</th>



          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>
          {console.log("historyData", historyData)}
          {historyData.map((historyItem, index) => (
            <tr key={historyItem.id}>
              <td>{historyItem.designation}</td>
              <td>{formatDate(historyItem.promotedDate)}</td>
              <td>{historyItem.location}</td>
              <td>{formatDate(historyItem.relocatedDate)}</td>

              <td>{historyItem.employeeStatus}</td>
              {historyItem.employeeStatus === "Relieved" ? (
                // Conditionally render Relieved Date cell
                <td>{formatDate(historyItem.relievedDate)}</td>
              ) : (
                // Render an empty cell if status is not "Relieved"
                <td></td>
              )}
              <td>{historyItem.status}</td>
              {historyItem.status === "Inactive" ? (
                <td>{formatDate(historyItem.inActiveDate)}</td>
              ) : (
                <td></td>
              )}
              <td>{historyItem.inActivePeriod}</td>

              {/* <td>{historyItem.inActiveReason}</td> */}
              {/* Add other table cells based on your response data */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return null;
    }

    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const formatDate_test = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };
  // const downloadAllDocuments = () => {
  //     const zip = new JSZip();

  //     // Iterate through each type of document
  //     const documentTypes = ['profilePhoto', 'resume', 'nda', 'educationalDocuments', 'paySlips'];

  //     documentTypes.forEach((documentType) => {
  //         const documents = selectedEmployee.totalDocuments.find((doc) => doc[documentType]);

  //         if (documents && documents[documentType].length > 0) {
  //             const folder = zip.folder(documentType);

  //             documents[documentType].forEach(async (document, index) => {
  //                 // You need to replace 'base64String' with the actual base64 content of the document
  //                 // You may need to modify your API to return base64 content or fetch it separately
  //                 // You can use the 'axios' library or the browser's 'fetch' API to fetch the content
  //                 const base64String = ''; // Replace with actual base64 content
  //                 try {
  //                     const response = await axios.get(`http://localhost:8080/api/binaryConverter?fileName=${fileName}&employeeId=${employeeId}`, {
  //                         responseType: 'arraybuffer', // Set the responseType to 'arraybuffer' to receive binary data
  //                     });

  //                     // Create a blob from the binary data received
  //                     const blob = new Blob([response.data]);

  //                     // Create a URL for the blob data
  //                     const imageUrl = URL.createObjectURL(blob);

  //                     // Open the image in a new tab
  //                     const newTab = window.open();
  //                     // Resize the image before displaying it
  //                     const maxImageWidth = 500; // Set your desired maximum width
  //                     const img = new Image();

  //                     img.onload = () => {
  //                         const canvas = newTab.document.createElement('canvas');
  //                         const ctx = canvas.getContext('2d');

  //                         const scaleFactor = maxImageWidth / img.width;
  //                         canvas.width = maxImageWidth;
  //                         canvas.height = img.height * scaleFactor;

  //                         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  //                         // Convert the canvas content to a data URL
  //                         const resizedImageUrl = canvas.toDataURL('image/jpeg'); // Adjust format and quality as needed

  //                     };
  //                     img.src = imageUrl;
  //                     // Display the image on the screen or do whatever you need with the binary data
  //                     console.log(imageUrl);
  //                     // setImageUrl(imageUrl);
  //                     // Set the imageUrl state or use it as needed in your component
  //                 } catch (error) {
  //                     console.error('Error fetching binary data:', error);
  //                     // Handle errors here
  //                 }

  //                 // Add each document to the zip folder
  //                 folder.file(`${documentType}_${index + 1}.${document.split('.').pop()}`, base64String, { base64: true });
  //             });
  //         }
  //     });

  //     // Generate the zip file
  //     zip.generateAsync({ type: 'blob' }).then((content) => {
  //         // Create a download link
  //         const link = document.createElement('a');
  //         link.href = URL.createObjectURL(content);
  //         link.download = 'employee_documents.zip';

  //         // Trigger the download
  //         link.click();
  //     });
  // };

  const handleFileNameClick = async (fileName, employeeId) => {
    const fileType = fileName.split('.').pop().toLowerCase();

    const response = await axios.get(process.env.REACT_APP_IP + `api/binaryConverter?fileName=${fileName}&employeeId=${employeeId}`, {
      responseType: 'arraybuffer', // Set the responseType to 'arraybuffer' to receive binary data
    });
    if (['png', 'jpg', 'jpeg'].includes(fileType)) {
      try {

        // Create a blob from the binary data received
        const blob = new Blob([response.data]);

        // Create a URL for the blob data
        const imageUrl = URL.createObjectURL(blob);

        // Open the image in a new tab
        const newTab = window.open();
        // Resize the image before displaying it
        const maxImageWidth = 500; // Set your desired maximum width
        const img = new Image();

        img.onload = () => {
          const canvas = newTab.document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const scaleFactor = maxImageWidth / img.width;
          canvas.width = maxImageWidth;
          canvas.height = img.height * scaleFactor;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convert the canvas content to a data URL
          const resizedImageUrl = canvas.toDataURL('image/jpeg'); // Adjust format and quality as needed


          newTab.document.write(`
            <html>
              <head>
                <title>Image</title>
                <style>
                  body { margin: 0; padding: 0; }
                  img { max-width: 100%; }
                  .download-container { position: fixed; top: 0; width: 100%; text-align: right; background-color: #f0f0f0; padding: 10px; }
                  .download-button { padding: 8px 16px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 4px; font-weight: bold; }
                </style>
              </head>
              <body>
                <div class="download-container">
                  <a class="download-button" href="${resizedImageUrl}" download="${fileName}">Download Image</a>
                </div>
                <img src="${resizedImageUrl}" alt="Profile Photo" />
              </body>
            </html>
          `);

          // newTab.document.write(`<html><head><title>Image</title></head><body><img src="${imageUrl}" alt="Profile Photo" style="max-width: 100%;" /></body></html>`);

          // // Create a download button
          // const downloadButton = newTab.document.createElement('a');
          // downloadButton.href = imageUrl;
          // downloadButton.download = fileName;
          // downloadButton.innerHTML = 'Download Image';
          // newTab.document.body.appendChild(downloadButton);

          // Clean up the URL when the new tab is closed
          newTab.onbeforeunload = () => {
            URL.revokeObjectURL(imageUrl);
            URL.revokeObjectURL(resizedImageUrl);
          };

        };
        img.src = imageUrl;
        // Display the image on the screen or do whatever you need with the binary data
        console.log(imageUrl);
        // setImageUrl(imageUrl);
        // Set the imageUrl state or use it as needed in your component
      } catch (error) {
        console.error('Error fetching binary data:', error);
        // Handle errors here
      }
    } else if (fileType === 'pdf') {
      try {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const newTab = window.open();

        newTab.document.write(`
                    <html>
                        <head>
                            <title>PDF Document</title>
                        </head>
                        <body>
                            <embed width="100%" height="100%" src="${pdfUrl}" type="application/pdf">
                        </body>
                    </html>
                `);

        newTab.onbeforeunload = () => {
          URL.revokeObjectURL(pdfUrl);
        };
      } catch (error) {
        console.error('Error handling PDF:', error);
      }
    } else if (['doc', 'docx'].includes(fileType)) {
      console.log("fileType", fileType)
      try {
        // Create a blob from the binary data received
        const docBlob = new Blob([response.data], { type: 'application/msword' }); // Use 'application/msword' for .doc files

        // Create a URL for the blob data
        const docUrl = URL.createObjectURL(docBlob);

        // Open the document in a new tab
        const newTab = window.open();

        // Write the HTML content to display the document
        newTab.document.write(`
                        <html>
                            <head>
                                <title>Word Document</title>
                            </head>
                            <body>
                                <iframe width="100%" height="100%" src="${docUrl}" type="application/msword"></iframe>
                            </body>
                        </html>
                    `);

        // Clean up the URL when the new tab is closed
        newTab.onbeforeunload = () => {
          URL.revokeObjectURL(docUrl);
        };
      } catch (error) {
        console.error('Error handling Word document:', error);
      }
    } else {
      // Handle other document types or show an error message
      console.error('Unsupported document type:', fileType);
    }


  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  useEffect(() => {
    // Retrieve selected employee details from sessionStorage
    var storedEmployee = sessionStorage.getItem('EmployeeId');
    console.log(storedEmployee)
    console.log("locationOptions", locationOptions)
    setEmployeeID(storedEmployee)
    const token = localStorage.getItem('Token');
    // Fetch employee details from the database and update the state
    const fetchData = async () => {
      try {
        // Retrieve the token from local storage

        if (!token) {
          navigate("/login")
        }

        if (storedEmployee == null) {
          navigate("/dashboard")
        }
        console.log(storedEmployee)
        const response = await axiosInstance.get('api/getEmployeeById/' + storedEmployee); // replace with your actual API endpoint
        console.log("storedEmployee", response.data)
        console.log("response", response)

        setSelectedEmployee(response.data);
        updateDocumentsRequired(response.data.country)

      } catch (error) {
        console.log("errror", error);

        // toast.error("Token Expired .......Please Login again....!", { position: toast.POSITION.TOP_CENTER })
        // sessionStorage.clear();
        // localStorage.clear();
        // localStorage.setItem("islogged", false);
        // navigate("/login")


      }
    };
    fetchData();

  }, []);

  useEffect(() => {
    const fetchProfilePhoto = async () => {
      if (selectedEmployee && selectedEmployee.totalDocuments) {
        const profilePhotoDoc = selectedEmployee.totalDocuments.find(doc => doc.profilePhoto && doc.profilePhoto.length > 0);
        if (profilePhotoDoc) {
          const s3Url = profilePhotoDoc.profilePhoto[0].s3Url;
          const key = s3Url.split('s3.amazonaws.com/')[1];

          try {
            // Set responseType to 'blob'
            const response = await axiosInstance.get(`api/getProfilePhoto?key=${key}`, {
              responseType: 'blob'
            });

            // Check if the response data is a Blob
            if (response.data instanceof Blob) {
              console.log('The response is a Blob.');
            } else {
              console.log('The response is NOT a Blob.');
            }

            // Create an object URL from the Blob
            const objectUrl = URL.createObjectURL(response.data);
            setImageSrc(objectUrl);

            // Clean up the object URL when the component unmounts or image changes
            return () => URL.revokeObjectURL(objectUrl);
          } catch (error) {
            console.error('Error fetching the image:', error);
            setImageSrc(null);
          }
        }
      }
    };

    if (selectedEmployee) {
      fetchProfilePhoto();
    }
  }, [selectedEmployee]);



  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="exalogo.png" alt="Logo" className="logo" />
          </a>
          <h2>
            <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
            <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
            <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
          </h2>

          <div className="d-flex align-items-center">
            <button className="btn text-primary" style={{ backgroundColor: "white", color: "blue", marginRight: "1rem" }} onClick={handleBack}>
              Back To Dashboard
            </button>
          </div>

        </div>
      </nav>
      <div
        className="container mt-5 mb-3 w-75 d-flex flex-column"
        style={{
          boxShadow: "0px 0px 5px #000",
          borderRadius: "5px",
          padding: "2rem",
          backgroundColor: "white",
        }}
      >

        {isLoading && (
          <div className="loader">
            <div className="circular-loader"></div>
          </div>
        )}
        <div className="text-center">
          <div
            className="rounded-circle bg-primary d-inline-flex align-items-center justify-content-center"
            style={{ width: "100px", height: "100px" }}
          >
            {imageSrc ? (
              <img
                src={imageSrc}
                alt={selectedEmployee ? selectedEmployee.employeeName : 'Employee'}
                style={{
                  width: "100%", // Make the image fill the container
                  height: "100%", // Make the image fill the container
                  objectFit: "cover", // Maintain aspect ratio while covering the container
                  borderRadius: "50%",
                  zIndex: 99,
                }}
              />
            ) : (
              <h1 style={{ margin: 0 }}>
                {selectedEmployee && selectedEmployee.employeeName
                  ? selectedEmployee.employeeName.charAt(0)
                  : ""}
              </h1>
            )}
          </div>
          <h2>{selectedEmployee && selectedEmployee.employeeName}</h2>
        </div>
        <div className="d-flex justify-content-end mb-3" style={{}}>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              + More Options
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleHistory}>History</Dropdown.Item>
              <Dropdown.Item onClick={handlePrint}>Print</Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  handleShow();
                  setcontentEditable(!contentEditable);
                }}
              >
                Edit Profile
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => { handleShow(); setcontentEditable(!contentEditable); }}>Financial Info </Dropdown.Item> */}

              <Dropdown.Item onClick={handleDownloadCSV}>
                Download CSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>



        <div className="mt-3 " style={{ textAlign: "left" }} id="print-content">
          {selectedEmployee && (
            <div style={{ marginLeft: "7rem", backgroundColor: "white" }}>
              <div className="row mb-4">
                <div className="col-md-6">
                  <div>
                    <strong>Employee ID : </strong>

                    <span>{selectedEmployee.employeeId}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Employee Name: </strong>
                    {isAdmin || isSuperAdmin ? (
                      <span
                        contentEditable={contentEditable}
                        className={`p-2 ${contentEditable ? "editable-field" : ""
                          }`}
                        onInput={(e) => {
                          let inputValue = e.target.textContent.replace(
                            /[^a-zA-Z\s]/g,
                            ""
                          ); // Remove non-digit characters

                          e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                          handleInputChange({
                            name: "employeeName",
                            value: inputValue,
                          });
                        }}
                      >
                        {selectedEmployee.employeeName}
                      </span>
                    ) : (
                      <span>{selectedEmployee.employeeName}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <div>
                    <strong>Email ID : </strong>
                    {isAdmin || isSuperAdmin ? (
                      <span
                        contentEditable={contentEditable}
                        className={`p-2 ${contentEditable ? "editable-field" : ""}`}
                        onBlur={(e) => {
                          const inputValue = e.target.textContent.trim(); // Get the input value

                          // Regular expression for validating email format
                          const emailPattern = /^[a-zA-Z]+@exafluence\.com$/;

                          if (emailPattern.test(inputValue)) {
                            // If valid email, update the state
                            handleInputChange({
                              name: "emailId",
                              value: inputValue,
                            });
                          } else {
                            // If invalid email, show an error or reset the value
                            e.target.textContent = ""; // Optionally clear invalid email
                            alert("Invalid email format. Please use a valid exafluence.com email.");
                          }
                        }}
                      >
                        {selectedEmployee.emailId}
                      </span>
                    ) : (
                      <span>{selectedEmployee.emailId}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Date Of Birth : </strong>
                    {(isAdmin || isSuperAdmin) && contentEditable ? (
                      <DatePicker
                        selected={formatDate_test(editedProfile.dateOfBirth)}
                        onChange={(date) =>
                          handleInputChange({
                            name: "dateOfBirth",
                            value: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-control"
                      />
                    ) : (
                      <span>{formatDate(selectedEmployee.dateOfBirth)}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div>
                    <strong>Date Of Joining : </strong>
                    {(isAdmin || isSuperAdmin) && contentEditable ? (
                      <DatePicker
                        selected={formatDate_test(editedProfile.dateOfJoining)}
                        onChange={(date) =>
                          handleInputChange({
                            name: "dateOfJoining",
                            value: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-control"
                      />
                    ) : (
                      <span>{formatDate(selectedEmployee.dateOfJoining)}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Experience : </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      onInput={(e) => {
                        let inputValue = e.target.textContent.replace(
                          /[^0-9.]/g,
                          ""
                        ); // Allow digits and '.'

                        // Split inputValue by decimal to validate proper decimal format
                        const parts = inputValue.split(".");
                        if (parts.length > 2 || (parts.length === 2 && parts[1].length > 1)) {
                          inputValue = parts[0] + "." + parts[1].substring(0, 1); // Limit decimal places to one
                        }

                        if (inputValue.length < 2) {
                          inputValue = inputValue.substring(0, 2); // Limit string to first 10 digits
                        }
                        e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                        handleInputChange({
                          name: "overAllExperience",
                          value: inputValue,
                        });
                      }}
                    >
                      {" "}
                      {convertToDecimal(selectedEmployee.overAllExperience)}
                    </span>
                    {/* Info Icon with Tooltip and Popover */}
                    <Tooltip title="More Info" arrow>
                      <IconButton size="small" onClick={handlePopoverOpen}>
                        <InfoIcon fontSize="small" style={{ color: 'blue', fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>

                    {/* Popover component for additional experience information */}
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'center', // Aligns the popover with the center of the icon
                        horizontal: 'right', // Aligns the popover to the right of the icon
                      }}
                      transformOrigin={{
                        vertical: 'center', // Aligns the popover's center with the anchor's center
                        horizontal: 'left', // Positions the popover to the left of the icon
                      }}
                    >
                      <div style={{ padding: '10px', maxWidth: '200px' }}>
                        <strong>Exafluence Exp:</strong> {convertToDecimal(selectedEmployee.exfExperience )|| 'N/A'}
                        <br />
                        <strong>Prior Exp:</strong> {selectedEmployee.totalExperience || 'N/A'}
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div>
                    <strong>Department : </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      style={{ cursor: contentEditable ? "text" : "default" }}
                      onInput={(e) => {
                        let inputValue = e.target.textContent.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        ); // Remove non-digit characters

                        e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                        handleInputChange({
                          name: "department",
                          value: inputValue,
                        });
                      }}
                    >
                      {selectedEmployee.department}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Education Qualification: </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      style={{ cursor: contentEditable ? "text" : "default" }}
                      onInput={(e) => {
                        let inputValue = e.target.textContent.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        ); // Remove non-digit characters

                        e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                        handleInputChange({
                          name: "educationalQualification",
                          value: inputValue,
                        });
                      }}
                    >
                      {selectedEmployee.educationalQualification}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <strong>Country: </strong>
                  {contentEditable ? (
                    <select
                      className="editable-field form-select"
                      style={{ width: "150px" }}
                      value={editedProfile.country} // Assuming you have `editedProfile.country` in the state
                      onChange={(e) =>
                        handleInputChange({
                          name: "country",
                          value: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Country</option>
                      {countryOptions &&
                        countryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <span>{selectedEmployee.country}</span> // Default display when not editable
                  )}
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Phone Number </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      style={{ cursor: contentEditable ? "text" : "default" }}
                      onInput={(e) => {
                        let inputValue = e.target.textContent.replace(
                          /\D/g,
                          ""
                        ); // Remove non-digit characters
                        if (inputValue.length > 10) {
                          inputValue = inputValue.substring(0, 10); // Limit string to first 10 digits
                        }
                        e.target.textContent = inputValue; // Update the textContent with formatted/limited string
                        handleInputChange({
                          name: "phoneNumber",
                          value: inputValue,
                        });
                      }}
                    >
                      {selectedEmployee.phoneNumber}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div className="row mb-4">
                  <div className="col-md-6 ">
                    <strong>Designation: </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      style={{ cursor: contentEditable ? "text" : "default" }}
                      onInput={(e) =>
                        handleInputChange({
                          name: "designation",
                          value: e.target.textContent,
                        })
                      }
                    >
                      {selectedEmployee.designation}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <strong>Location: </strong>
                    {contentEditable ? (
                      <select
                        className="editable-field form-select"
                        style={{ width: "150px" }}
                        value={editedProfile.location}
                        onChange={(e) =>
                          handleInputChange({
                            name: "location",
                            value: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Location</option>
                        {locationOptions &&
                          locationOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <span>{selectedEmployee.location}</span>
                    )}
                  </div>
                </div>

                {contentEditable && (
                  <div className="row mb-4">
                    <div className="col-md-6 ">
                      <strong>Promoted Date: </strong>{" "}
                      <DatePicker
                        selected={formatDate_test(editedProfile.promotedDate)}
                        onChange={(date) =>
                          handleInputChange({
                            name: "promotedDate",
                            value: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <strong>Relocated On: </strong>{" "}
                      <DatePicker
                        selected={formatDate_test(editedProfile.relocatedDate)}
                        onChange={(date) =>
                          handleInputChange({
                            name: "relocatedDate",
                            value: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div>
                    <strong>Onsite / Offshore : </strong>{" "}
                    {contentEditable ? (
                      <select
                        className="editable-field form-select"
                        style={{ width: "150px" }}
                        value={editedProfile.onsiteOffshore} // Assuming `editedProfile.onsiteOffshore` is managed in your state
                        onChange={(e) =>
                          handleInputChange({
                            name: "onsiteOffshore",
                            value: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="Onsite">Onsite</option>
                        <option value="Offshore">Offshore</option>
                      </select>
                    ) : (
                      <span>{selectedEmployee.onsiteOffshore}</span> // Display the value if not editable
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Induction and Onboarding: </strong>{" "}
                    {contentEditable ? (
                      <select
                        className="editable-field form-select"
                        style={{ width: "150px" }}
                        value={editedProfile.inductionOnboarding} // Assuming `editedProfile.onsiteOffshore` is managed in your state
                        onChange={(e) =>
                          handleInputChange({
                            name: "inductionOnboarding",
                            value: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    ) : (
                      <span>{selectedEmployee.inductionOnboarding ? "Yes" : "No"}</span> // Display the value if not editable
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  {/* <li><strong>Technical Skills : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'technicalskills', value: e.target.textContent })}> {selectedEmployee.technicalskills}</span></li> */}
                  <div>
                    <strong>Primary Technical Skills: </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      onInput={(e) => {
                        const updatedSkills = e.target.textContent.trim();
                        handleInputChange({
                          name: "primaryTechnicalskills",
                          value: updatedSkills,
                        });
                      }}
                    >
                      {Array.isArray(selectedEmployee.primaryTechnicalskills)
                        ? selectedEmployee.primaryTechnicalskills.join(", ")
                        : selectedEmployee.primaryTechnicalskills}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <li><strong>Technical Skills : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'technicalskills', value: e.target.textContent })}> {selectedEmployee.technicalskills}</span></li> */}
                  <div>
                    <strong>Secondary Technical Skills: </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      onInput={(e) => {
                        const updatedSkills = e.target.textContent.trim();
                        handleInputChange({
                          name: "secondaryTechnicalskills",
                          value: updatedSkills,
                        });
                      }}
                    >
                      {Array.isArray(selectedEmployee.secondaryTechnicalskills)
                        ? selectedEmployee.secondaryTechnicalskills.join(", ")
                        : selectedEmployee.secondaryTechnicalskills}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  {/* <li><strong>Technical Skills : </strong><span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'technicalskills', value: e.target.textContent })}> {selectedEmployee.technicalskills}</span></li> */}
                  <div>
                    <strong>Other Technical Skills: </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      onInput={(e) => {
                        const updatedSkills = e.target.textContent.trim();
                        handleInputChange({
                          name: "otherTechnicalskills",
                          value: updatedSkills,
                        });
                      }}
                    >
                      {Array.isArray(selectedEmployee.otherTechnicalskills)
                        ? selectedEmployee.otherTechnicalskills.join(", ")
                        : selectedEmployee.otherTechnicalskills}
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  {/* <li><strong> Certifications : </strong> <span contentEditable={contentEditable} className={`p-2 ${contentEditable ? 'editable-field' : ''}`} onInput={e => handleInputChange({ name: 'certifications', value: e.target.textContent })}>{selectedEmployee.certifications}</span></li> */}
                  <div>
                    <strong>Certifications: </strong>
                    <span
                      contentEditable={contentEditable}
                      className={`p-2 ${contentEditable ? "editable-field" : ""
                        }`}
                      onInput={(e) => {
                        const updatedSkills = e.target.textContent.trim();
                        handleInputChange({
                          name: "certifications",
                          value: updatedSkills,
                        });
                      }}
                    >
                      {Array.isArray(selectedEmployee.certifications)
                        ? selectedEmployee.certifications.join(", ")
                        : selectedEmployee.certifications}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div>
                    <strong>Background Verification : </strong>{" "}
                    {contentEditable ? (
                      <select
                        className="editable-field form-select"
                        style={{ width: "150px" }}
                        value={editedProfile.backgroundVerification} // Assuming `editedProfile.onsiteOffshore` is managed in your state
                        onChange={(e) =>
                          handleInputChange({
                            name: "backgroundVerification",
                            value: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    ) : (
                      <span>{selectedEmployee.backgroundVerification ? "Yes" : "No"}</span> // Display the value if not editable
                    )}

                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <strong>Statutory : </strong>{" "}
                    {selectedEmployee.statutory &&
                      selectedEmployee.statutory.map((item, index) => (
                        <span key={index}>
                          {item}
                          {index !== selectedEmployee.statutory.length - 1 &&
                            ", "}
                        </span>
                      ))}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <strong>Employee Status: </strong>
                  {contentEditable ? (
                    <select
                      className="editable-field form-select"
                      value={editedProfile.employeeStatus}
                      style={{ width: "150px" }}
                      onChange={(e) =>
                        handleInputChange({
                          name: "employeeStatus",
                          value: e.target.value,
                        })
                      }
                    >
                      <option value="Working">Working</option>
                      <option value="Relieved">Relieved</option>
                    </select>
                  ) : (
                    <span>{selectedEmployee.employeeStatus}</span>
                  )}
                </div>
                <div className="col-md-6">
                  {editedProfile.employeeStatus === "Relieved" &&
                    contentEditable && (
                      <div className="col-md-6 mt-3 mt-md-0">
                        <strong>Relieved Date: </strong>{" "}
                        <DatePicker
                          selected={formatDate_test(editedProfile.relievedDate)}
                          onChange={(date) =>
                            handleInputChange({
                              name: "relievedDate",
                              value: date,
                            })
                          }
                          dateFormat="dd/MM/yyyy"
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          className="form-control"
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <strong>Working Type:</strong>
                  {contentEditable ? (
                    <select
                      className="editable-field form-select"
                      style={{ width: "150px" }}
                      value={editedProfile.workingType}
                      onChange={(e) =>
                        handleInputChange({
                          name: "workingType",
                          value: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Type</option>
                      {workingOptions &&
                        workingOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </select>

                  ) : (
                    <span> {selectedEmployee.workingType}</span>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <strong> Status: </strong>
                  {contentEditable ? (
                    <select
                      className="editable-field form-control"
                      style={{ width: "150px" }}
                      value={editedProfile.status}
                      onChange={(e) =>
                        handleInputChange({
                          name: "status",
                          value: e.target.value,
                        })
                      }
                    >
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </select>
                  ) : (
                    <span>{selectedEmployee.status}</span>
                  )}
                </div>
                {editedProfile.status && contentEditable && (
                  <div className="col-md-6">
                    {editedProfile.status === "InActive" && (
                      <div className="row ">
                        <div className="col-md-6 mb-3">
                          <strong> InActivation Date: </strong>{" "}
                          <DatePicker
                            selected={formatDate_test(editedProfile.inActiveDate)}
                            onChange={(date) =>
                              handleInputChange({
                                name: "inActiveDate",
                                value: date,
                              })
                            }
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <strong> InActive Reason: </strong>{" "}
                          <input
                            type="text"
                            className="form-control"
                            id="inActiveReason"
                            name="inActiveReason"
                            value={selectedEmployee.inActiveReason}
                            onChange={(e) =>
                              handleInputChange({
                                name: "inActiveReason",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    )}
                    {contentEditable && editedProfile.status === "Active" && (
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <strong> Activation Date: </strong>{" "}
                          <DatePicker
                            selected={formatDate_test(editedProfile.activeDate)}
                            onChange={(date) =>
                              handleInputChange({
                                name: "activeDate",
                                value: date,
                              })
                            }
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Add a dropdown for 'Status' */}
            </div>
          )}
        </div>
        <div>
          <Typography variant="h6" component="div" style={{ marginBottom: '16px', textAlign: 'center' }}>
            Document Details
          </Typography>

          {(isAdmin || isSuperAdmin) && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>

              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => handleEditClick('all')}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download All" arrow>
                <IconButton onClick={handleDownloadAll}>
                  <Archive />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download" arrow>
                <IconButton onClick={() => {
                  const format = prompt("Enter download format (excel, pdf):");
                  if (format) {
                    handleDownloadFormat(format.toLowerCase());
                  }
                }}>
                  <FileDownload />
                </IconButton>
              </Tooltip>
              <Tooltip title="Print" arrow>
                <IconButton onClick={handlePrintDoc}>
                  <Print />
                </IconButton>
              </Tooltip>
            </div>
          )}

          <TableContainer component={Paper}>
            {isLoading && <p>Loading...</p>} {/* Loader */}
            <Table id="printableTable">
              <TableHead>
                <TableRow>
                  <TableCell>Documents List</TableCell>
                  <TableCell>Provided Documents</TableCell>
                  {isEditMode && <TableCell>Document Name</TableCell>} {/* Show only if in edit mode */}
                  {isEditMode && <TableCell>Actions</TableCell>} {/* Show only if in edit mode */}

                </TableRow>
              </TableHead>
              <TableBody>
                {selectedEmployee && selectedEmployee.totalDocuments && selectedEmployee.totalDocuments.length > 0 ? (
                  selectedEmployee.totalDocuments.map((document, docIndex) => {
                    const docType = Object.keys(document)[0];
                    const docDetails = document[docType];
                    const documentExists = Object.keys(documentsRequired).includes(docType);

                    if (documentExists) {
                      return (
                        <TableRow key={docIndex}>
                          <TableCell>{docType}</TableCell>
                          <TableCell>
                            {/* Conditional rendering for green tick or red cross */}
                            {docDetails.length > 0 ? (
                              <CheckCircle style={{ color: 'green' }} />
                            ) : (
                              <Cancel style={{ color: 'red' }} />
                            )}
                          </TableCell>

                          {isEditMode && (
                            <TableCell>
                              {docDetails.length > 0 ? (
                                docDetails.map((doc, innerIndex) => (
                                  <p key={innerIndex}>{doc.fileName}</p>
                                ))
                              ) : (
                                <p>No documents provided.</p>
                              )}
                            </TableCell>
                          )}

                          {isEditMode && (
                            <TableCell>
                              {docDetails.length > 0 ? (
                                docDetails.map((doc, innerIndex) => (
                                  <div key={innerIndex} style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={() => handleDownload(selectedEmployee.employeeId, docType, doc.fileName)} color="primary">
                                      <CloudDownload />
                                    </IconButton>
                                    <IconButton onClick={(event) => handleDelete(selectedEmployee.employeeId, doc.fileName, event)} color="secondary">
                                      <Delete />
                                    </IconButton>
                                    {/* For uploading new documents */}
                                    <input
                                      type="file"
                                      accept={docType === 'profilePhoto' ? "image/png, image/jpg, image/jpeg" : "*/*"} // Accept all types for non-profile photos
                                      onChange={(event) => {
                                        const file = event.target.files[0];
                                        if (file) {
                                          handleUpload(docType, file); // Pass the file object here
                                        }
                                      }}
                                      style={{ display: 'none' }} // Hide the input
                                      id={`upload-${innerIndex}`} // Unique ID for each upload input
                                    />
                                    <label htmlFor={`upload-${innerIndex}`}>
                                      <IconButton color="default" component="span">
                                        <CloudUpload />
                                      </IconButton>
                                    </label>
                                  </div>
                                ))
                              ) : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    type="file"
                                    accept={docType === 'profilePhoto' ? "image/png, image/jpg, image/jpeg" : "*/*"} // Accept all types for non-profile photos
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      if (file) {
                                        handleUpload(docType, file); // Pass the file object here
                                      }
                                    }}
                                    style={{ display: 'none' }} // Hide the input
                                    id={`upload-new-${docType}`} // Unique ID for new upload input
                                  />
                                  <label htmlFor={`upload-new-${docType}`}>
                                    <IconButton color="default" component="span">
                                      <CloudUpload />
                                    </IconButton>
                                  </label>
                                </div>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>No Documents Available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>


        </div>

        <div className='mt-5' id="history">
          {isHistoryVisible && (
            <div>
              <Typography
                variant="h6"
                component="div"
              // style={{ marginBottom: '10px', textAlign: 'center' }}
              >
                Employee History
              </Typography>


              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>

                <Tooltip title="Download" arrow>
                  <IconButton
                    onClick={() => {
                      const format = "excel";
                      if (format) {
                        handleHistoryDownload(format.toLowerCase());
                      }
                    }}
                  >
                    <FileDownload />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Print" arrow>
                  <IconButton onClick={handlePrintHistory}>
                    <Print />
                  </IconButton>
                </Tooltip>
              </div>


              <TableContainer component={Paper}>
                {isLoading && <p>Loading...</p>} {/* Loader */}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Designation</TableCell>
                      <TableCell>Promoted Date</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Relocated Date</TableCell>
                      <TableCell>Employee Status</TableCell>
                      <TableCell>Relieved Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Inactive Date</TableCell>
                      <TableCell>Inactive Period</TableCell>
                      <TableCell>Inactive Reason</TableCell>
                      <TableCell>Actions</TableCell> {/* Show only if in edit mode */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyData.length > 0 ? (
                      // Sort historyData by promotedDate and relocatedDate
                      historyData
                        .sort((a, b) => {
                          const promotedDateA = new Date(a.promotedDate);
                          const promotedDateB = new Date(b.promotedDate);
                          const relocatedDateA = new Date(a.relocatedDate);
                          const relocatedDateB = new Date(b.relocatedDate);

                          // Compare promotedDate first, then relocatedDate
                          if (promotedDateA < promotedDateB) return -1;
                          if (promotedDateA > promotedDateB) return 1;
                          // If promoted dates are equal, compare relocated dates
                          if (relocatedDateA < relocatedDateB) return -1;
                          if (relocatedDateA > relocatedDateB) return 1;

                          return 0; // Equal
                        })
                        .map((entry, entryIndex) => (
                          <TableRow key={entryIndex}>
                            <TableCell>{entry.designation}</TableCell>
                            <TableCell>{formatDate(entry.promotedDate)}</TableCell>
                            <TableCell>{entry.location}</TableCell>
                            <TableCell>{formatDate(entry.relocatedDate)}</TableCell>
                            <TableCell>{entry.employeeStatus}</TableCell>
                            <TableCell>{formatDate(entry.relievedDate)}</TableCell>
                            <TableCell>{entry.status}</TableCell>
                            <TableCell>{formatDate(entry.inActiveDate)}</TableCell>
                            <TableCell>{entry.inActivePeriod}</TableCell>
                            <TableCell>{entry.inActiveReason}</TableCell>
                            <TableCell>
                              {/* Add any actions you want to provide in edit mode, e.g., delete */}
                              <IconButton onClick={() => handleDeleteHistory(selectedEmployee.employeeId, 'workHistory', entryIndex)} color="secondary">
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={isEditMode ? 11 : 10}>
                          No History Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          )}

        </div>



        <div className="container">
          <div className="row text-center">
            <div className="col-md-6">
              <button
                onClick={handleSave}
                className="btn btn-primary"
                style={{ width: "100%" }}
              >
                Save Changes
              </button>
            </div>
            <div className="col-md-6">
              <button
                onClick={handleBack}
                className="btn btn-primary"
                style={{ width: "100%" }}
              >
                Back To Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Profile;